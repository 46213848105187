import {
  API, deepClone
} from '../../common'
import { showNotification } from '../../../store/shared'
import pubsub from 'models/pubsub'
import { UserEntitlement } from '../../../models/constants'
import { sanitized } from '../../../helpers'

const DefaultUserDetails = {
  'name': '',
  'surname': '',
  'email': '',
  'roles': [],
  'entitlements': [],
  'status':'Draft'
}
// ------------------------------------
// Constants
// // ------------------------------------

export const LOAD_USER = 'USER_EDITOR/LOAD_USER'
export const UPDATE_USER = 'USER_EDITOR/UPDATE_USER'
export const SET_DUPLICATE_EMAIL_ERROR_MESSAGE = 'USER_EDITOR/DUPLICATE_EMAIL_ADDRESS_ERROR_MESSAGE'
export const DUPLICATE_EMAIL_ERROR_KEY = 'duplicate.email.address'
// ------------------------------------
// Actions
// ------------------------------------

function loadUser (userId) {
  if (!userId) {
    return Promise.resolve({ ...DefaultUserDetails })
  }
  return API.getJson(`/api/platform/user/${userId}`)
}

export const loadData = (userId, mode = '') => (dispatch) => {
  Promise.all([
    loadUser(userId)
  ]).then(response => {
    const [userDetails] = response
    pubsub.publish(
      'title-bar-content',
      `Config User: <b>${userDetails.name}</b> <b>${userDetails.surname}</b>`
    )

    dispatch({
      type: LOAD_USER,
      payload: {
        isAddNew: mode.toLowerCase() === 'new',
        userDetails: { ...userDetails,
          isUserAdmin: checkIsUserAdmin(userDetails.entitlements)
        },
        isLoading: false
      }
    })
  }).catch(error => {
    API.handleError(error, dispatch)
  })
}

export const updateUser = (values) => (dispatch) => {
  const { name, surname, email, ...otherValues } = values
  const sanitizedValues = {
    ...otherValues,
    name: sanitized(name),
    surname: sanitized(surname),
    email: sanitized(email)
  }
  const userDetails = {
    ...sanitizedValues,
    entitlements: getUserEntitlements(values.isUserAdmin)
  }
  const payload = { ...userDetails }
  if (payload.id) {
    var updateUserUrl = `/api/platform/user/${payload.id}/update`

    API.post(updateUserUrl, JSON.stringify(payload))
      .then(response => {
        if (!response.ok) {
          throw response
        }
        dispatch({
          type: UPDATE_USER,
          payload: userDetails
        })
        dispatch(showNotification({
          type: 'success',
          headline: 'Success',
          message: `Save of details for ${payload.name} ${payload.surname} was successful`
        }))
      }).catch(error => {
        API.handleError(error, dispatch)
      })
  } else {
    API.post(`/api/platform/user`, JSON.stringify(payload)).then(response => {
      if (!response.ok) {
        throw response
      }
      dispatch({
        type: UPDATE_USER,
        payload: userDetails
      })
      dispatch(showNotification({
        type: 'success',
        headline: 'Success',
        message: `New user  ${payload.name} ${payload.surname} saved successfully`

      }))
      return response.json()
    }).then(data => {
      dispatch(loadData(data.id))
    }).catch(error => {
      handleAddNewUserError(error, dispatch)
    })
  }
}

const handleAddNewUserError = (error, dispatch) => {
  const errorCopy = error.clone()
  Promise.resolve(errorCopy.json())
    .then(data => {
      if (data.key === DUPLICATE_EMAIL_ERROR_KEY) {
        dispatch(setDuplicateEmailErrorMessage(data))
      } else {
        API.handleError(error, dispatch)
      }
    })
}

const setDuplicateEmailErrorMessage = (obj) => dispatch => {
  dispatch({
    type:  SET_DUPLICATE_EMAIL_ERROR_MESSAGE,
    payload: obj.message
  })
}

export const resetDuplicateEmailErrorMessage = () => dispatch => {
  dispatch({
    type:  SET_DUPLICATE_EMAIL_ERROR_MESSAGE,
    payload: null
  })
}

const getUserEntitlements = (isUserAdmin) => {
  const adminEntitlements = Object.values(UserEntitlement)
  const userEntitlement = new Array(UserEntitlement.canSeeRegisterRowData)
  return isUserAdmin ? adminEntitlements : userEntitlement
}

export const checkIsUserAdmin = (entitlements) => {
  const adminEntitlements = Object.values(UserEntitlement)
  if (!entitlements) return false
  if (entitlements.length !== adminEntitlements.length) return false
  return isAllAdminEntitlementsMatch(entitlements, adminEntitlements)
}

const isAllAdminEntitlementsMatch = (entitlements, adminEntitlements) => {
  let counter = 0
  for (const entitlement of adminEntitlements) {
    if (entitlements.indexOf(entitlement) > -1) {
      counter++
    }
  }
  return counter === adminEntitlements.length
}

export const exitPage = () => () => {
  location.href = `/user-list`
}

export const activateUser = (userData) => (dispatch) => {
  API.post(`/api/platform/user/${userData.id}/activate`)
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      dispatch(showNotification({
        type: 'success',
        headline: 'Success',
        message: `Activate ${userData.name} ${userData.surname} was successful`
      }))
      dispatch(loadData(userData.id))
    })
    .catch(error => {
      API.handleError(error, dispatch)
    })
}

export const deactivateUser = (userData) => (dispatch) => {
  const payload = {
    reason: userData.deactivation.reason.id,
    comment: sanitized(userData.deactivation.comment)
  }
  API.post(`/api/platform/user/${userData.id}/deactivate`, JSON.stringify(payload))
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      dispatch(showNotification({
        type: 'success',
        headline: 'Success',
        message: `Deactivate ${userData.name} ${userData.surname} was successful`
      }))
      dispatch(loadData(userData.id))
    })
    .catch(error => {
      API.handleError(error, dispatch)
    })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_USER]: (state, action) => {
    return {
      ...state,
      ...action.payload
    }
  },
  [UPDATE_USER]: (state, action) => {
    return {
      ...state,
      userDetails: action.payload

    }
  },
  [SET_DUPLICATE_EMAIL_ERROR_MESSAGE]: (state, action) => {
    return {
      ...state,
      duplicateEmailErrorMessage: action.payload
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoading: true,
  userDetails: deepClone(DefaultUserDetails),
  duplicateEmailErrorMessage: null

}

export default function userEditorReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
