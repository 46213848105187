import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { retrieveUserRole } from '../store/accessControl'
import Loader from '../components/Loader'

const mapDispatchToProps = {
  retrieveUserRole
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.auth.user,
  isRetrievingRole: state.accessControl.isRetrievingRole,
  roleError: state.accessControl.roleError,
  userRole: state.accessControl.userRole,
  reviewId: ownProps.params.reviewId
})

// High Order Component - userRoleProps which takes a BaseComponent and return a new component with the user
// role related properties injected
export default (BaseComponent) => {
  class UserRoleWrapper extends React.PureComponent {
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount () {
      const props = this.props
      props.retrieveUserRole(props.reviewId)
    }
    render () {
      const { isRetrievingRole, roleError } = this.props
      if (isRetrievingRole) {
        return <Loader text='Retrieving user role' />
      }
      if (roleError) {
        return (
          <Alert bsStyle='danger'>
            <h4>Authentication error</h4>
            <p style={{ 'wordWrap':'break-word' }}>{roleError}</p>
          </Alert>
        )
      }
      return <BaseComponent {...this.props} />
    }
  }
  UserRoleWrapper.propTypes = {
    retrieveUserRole: PropTypes.func,
    userRole: PropTypes.string,
    roleError: PropTypes.string,
    reviewId: PropTypes.string,
    isRetrievingRole: PropTypes.bool
  }

  return connect(mapStateToProps, mapDispatchToProps)(props => (
    <UserRoleWrapper {...props} />
  ))
}

export const UserRole = {
  Reviewer: 'REVIEWER',
  Approver: 'APPROVER',
  Owner: 'OWNER',
  None: 'NONE',
  CONTRIBUTOR: 'CONTRIBUTOR'
}
