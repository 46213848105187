
import CriterionQuestion from './criterionQuestion'
import CriterionSection from './criterionSection'

export const CriterionType = Object.freeze(
  {
    Question: 'Question',
    Section: 'Section'
  })

const CriterionMapping = Object.freeze({
  [CriterionType.Question] : CriterionQuestion,
  [CriterionType.Section] : CriterionSection
})

export const CriterionFactory = (criterionType) => (
  CriterionMapping[criterionType]
)
