
import { CriterionFactory } from './criterionFactory'

/*  "answers": "full answers set"
    "subjectType":"Question",
    "subjectValue":"<questionIdValue>",
    "operation":"Equals | NotEquals |Contains | More | Less | MoreOrEquals | LessOrEquals | IsNull | IsNotNull",
    "comparisonValue":"<value>" */
const Criterion = (params) => {
  const {
    subjectType
  } = params
  var criterion = CriterionFactory(subjectType)({ ...params })

  return {
    ...criterion
  }
}

export default Criterion
