// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/CoreLayout'
import DashboardRoute from './Dashboard'
import ReviewConfig from './ReviewConfig'
import ReviewDetails from './ReviewDetails'
import ReviewList from './ReviewList'
import Login from './Login'
import CaptureForm from './CaptureForm'
import CaptureFormEditor from './CaptureFormEditor'
import CaptureNewFormList from './CaptureNewFormList'
import CaptureTaskList from './CaptureTaskList'
import CaptureTemplate from './CaptureTemplate'
import CaptureTemplateList from './CaptureTemplateList'
import CaptureFormList from './CaptureFormList'
import HooksExample from './HooksExample'
import FormSubmissionList from './FormSubmissionList'
import Onboarding from './Onboarding'
import OnboardingDB from './OnboardingDB'
import OnboardingAll from './OnboardingAll'
import UserList from './UserList'
import UserGroupList from './UserGroupList'
import CaptureSubmissionReport from './CaptureSubmissionReport'
import RegistryScreen from './RegistryScreen'
import RegistryList from './RegisterList'
import UserEditor from './UserEditor'
import RegisterEditor from './RegisterEditor'
import RowAssessment from './RowAssessment'
import UserGroupEditor from './UserGroupEditor'
import ExternalUserGroupList from './ExternalUserGroupList'
import ExternalUserGroupEditor from './ExternalUserGroupEditor'
import ExternalUserList from './ExternalUserList'
import ExternalUserEditor from './ExternalUserEditor'
import ObserverCaptureFormList from './ObserverCaptureFormList'
import FormAssessments from './FormAssessments'
/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = (store) => ({
  path        : '/',
  component   : CoreLayout,
  indexRoute  : CaptureTaskList(store),
  childRoutes : [
    ReviewConfig(store),
    DashboardRoute(store),
    ReviewDetails(store),
    ReviewList(store),
    Login(store),
    CaptureForm(store),
    CaptureNewFormList(store),
    CaptureTaskList(store),
    CaptureTemplate(store),
    CaptureTemplateList(store),
    CaptureFormList(store),
    CaptureFormEditor(store),
    FormSubmissionList(store),
    HooksExample(),
    Onboarding(),
    OnboardingDB(),
    OnboardingAll(),
    UserList(),
    RegistryScreen(store),
    RegistryList(),
    ExternalUserGroupList(),
    UserGroupList(),
    ExternalUserGroupEditor(store),
    UserGroupEditor(store),
    CaptureSubmissionReport(),
    UserEditor(store),
    RegisterEditor(store),
    RowAssessment(store),
    ObserverCaptureFormList(store),
    FormAssessments(store),
    ExternalUserList(),
    ExternalUserEditor(store),
    ObserverCaptureFormList(store)
  ]
})

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

export default createRoutes
