import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path: 'register/(:registerId)/row-assessments/(:rowId)',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const container = require('./containers/RegisterRowAssessmentContainer').default
      const reducerNames = ['registerRowAssessment']
      reducerNames.forEach(key => {
        const reducer = require(`./modules/${key}`).default
        injectReducer(store, { key, reducer })
      })
      cb(null, container)
    }, 'RegisterRowAssessment')
  }
})
