import { UserEntitlement } from './constants'

export function GetUserEntitlements (user) {
  var userEntitlements = {}

  if (!(user && user.entitlements && user.entitlements.length > 0)) { return userEntitlements }

  const serverEntitlements = user.entitlements

  Object.keys(UserEntitlement).forEach(key => {
    const entitlement = UserEntitlement[key]
    userEntitlements[key] = serverEntitlements.some(e => String(e) === entitlement)
  })

  return userEntitlements
}
