export const TABLE_TYPE_CAPTURE = 'TABLE_TYPE_CAPTURE'
export const DefaultTooltipText = {
  banned: '',
  disabled: ''
}
export const NotSortColumnKeys = ['level', '_label', 'userOrUserGroup', 'actions']
export const NoResultPlaceholder = 'No Approvers have been added'
export const AllRegisteredUsers = {
  id: 'all_registered_users',
  name: 'All Registered Users',
  isAnyRegisteredUser: true,
  isUserGroup: true,
  status:'Active'
}
export const ADD_EDIT_APPROVER_FORM = 'ADD_EDIT_APPROVER_FORM'
export const APPROVER_ORDER = {
  first:{
    label: '1st',
    value: 1
  },
  second: {
    label: '2nd',
    value: 2
  },
  third: {
    label: '3rd',
    value: 3
  }
}

export const VALIDATION_MESSAGES = {
  SaveApproversError: 'Please enter a Label and select a User or User Group.',
  SaveCaptureTabError: 'Question Text for the Approval Question is missing.',
  SaveAnswerOptionError: 'Please enter the Answer Text for this Answer Option.'
}
export const DEFAULT_APPROVAL_QUESTION = 'What is your response to this assessment?'
export const DEFAULT_REVIEWER_QUESTION = 'What is your response to this assessment?'

export const DEFAULT_CHOICES = [
  {
    outcome: 'Approve',
    display: true,
    answerText: 'Approve',
    answerComment: 'Optional',
    optionValue: 1
  },
  {
    outcome: 'Reopen',
    display: true,
    answerText: 'Reopen',
    answerComment: 'Required',
    optionValue: 2
  },
  {
    outcome: 'Reject',
    display: true,
    answerText: 'Reject',
    answerComment: 'Required',
    optionValue: 3
  }
]

export const REVIEWER_DEFAULT_CHOICES = [
  {
    outcome: 'I Concur',
    display: true,
    answerText: 'I concur',
    answerComment: 'Optional',
    optionValue: 1
  },
  {
    outcome: 'I Do Not Concur',
    display: true,
    answerText: 'I do not concur',
    answerComment: 'Required',
    optionValue: 2
  }
]

export const ANSWER_COMMENTS = {
  Optional: 'Optional',
  Required: 'Required',
  Disabled: 'Disabled'
}

export const OUTCOMES = {
  Approve: {
    ui: 'Approved',
    backend: 'Approve'
  },
  Reopen: {
    ui: 'Reopened',
    backend: 'Reopen'
  },
  Reject: {
    ui: 'Rejected',
    backend: 'Reject'
  }
}

export const REVIEWER_OUTCOMES = {
  iConcur: 'I Concur',
  iDoNotConcur: 'I Do Not Concur'
}

export const EDIT_OPTION_FORM = 'EDIT_OPTION_FORM'
export const ANSWER_COMMENT_RADIO_BUTTONS = [
  ANSWER_COMMENTS.Required, ANSWER_COMMENTS.Optional, ANSWER_COMMENTS.Disabled
]
