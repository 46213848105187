import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : 'review-list',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const Dashboard = require('./containers/ReviewListContainer').default
      const reducer = require('./modules/reviewList').default

      injectReducer(store, { key: 'reviewList', reducer })

      /*  Return getComponent   */
      cb(null, Dashboard)

    /* Webpack named bundle   */
    }, 'reviewList')
  }
})
