import React from 'react'
import PropTypes from 'prop-types'

import { showNotification, dismissNotification, resetError, showValidationError } from '../store/shared'
import { AlertList } from 'react-bs-notifier'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import StandardButton from 'components/StandardButton'

const mapDispatchToProps = {
  showNotification,
  dismissNotification,
  resetError,
  showValidationError
}

const mapStateToProps = (state, ownProps) => ({
  notifications: state.shared.notifications,
  error: state.shared.error,
  isProcessingData: state.shared.isProcessingData,
  isExternalUser: state.auth.user.isExternalUser
})

export const withNotifications = (BaseComponent) => {
  const WrappedComponent = (props) => {
    const resetErrorAndNavigateToHome = () => {
      props.resetError()
      if (props.isExternalUser) {
        location.href = '/'
      }
    }
    return (
      <div>
        <AlertList
          position='top-right'
          alerts={props.notifications || []}
          timeout={3000}
          dismissTitle='Dismiss'
          onDismiss={props.dismissNotification}
        />
        {props.error &&
          <Modal className='capture-modal' // dialogClassName='withNotificationsModal'
            backdrop='static' show={!!props.error} onHide={props.error.action || resetErrorAndNavigateToHome}>
            <Modal.Header >
              <span >{props.error.title || 'WARNING'}</span>
            </Modal.Header>
            <Modal.Body>
              <p style={{ textAlign:'left', whiteSpace: 'pre-line', margin: '0 auto' }}>
                {props.error.message}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <StandardButton
                onClick={props.error.action || resetErrorAndNavigateToHome}
                text={props.error.actionTitle || 'OK'}
                btnType={props.error.extraAction ? 'secondary' : 'primary'}
              />
              {props.error.extraAction &&
              <StandardButton
                onClick={props.error.extraAction}
                text={props.error.extraActionTitle || 'Discard'}
                btnType='destructive'
              />
              }
            </Modal.Footer>
          </Modal>
        }
        {props.isProcessingData &&
          <Modal backdrop='static' bsSize='small' show={props.isProcessingData} >
            <Modal.Body>
              <p>Processing</p>
            </Modal.Body>
          </Modal>
        }
        <BaseComponent {...props} />
      </div>
    )
  }

  WrappedComponent.propTypes = {
    notifications: PropTypes.any,
    error: PropTypes.any,
    isProcessingData: PropTypes.bool,
    dismissNotification: PropTypes.func,
    resetError: PropTypes.func,
    isExternalUser: PropTypes.bool
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}

export default withNotifications
