import { CriterionOperationEnum } from './constants'
import { visitTreeInPreOrder } from '../../../../models/template'
import { getAnswersValue, getAnswersForSingleQuestion } from '../answer'
import { ElementKindType } from '../constants'

import _ from 'lodash'

const isValidNumber = (value) => {
  if (value) {
    if (!isNaN(value)) {
      return true
    }
  }
  return false
}

const equals = (answers, comparisonValue) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerNotEqualToValue = false
    answers.forEach(answer => {
      if (!_.isEqual(String(answer), String(comparisonValue))) {
        isAnyAnswerNotEqualToValue = true
      }
    })
    return !isAnyAnswerNotEqualToValue
  }
  return false
}

const notEquals = (answers, comparisonValue) => {
  if (isNull(answers)) return false

  return !equals(answers, comparisonValue)
}

const contains = (answers, comparisonValue, isOptionComparison) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerContainsValue = false
    answers.forEach(answer => {
      // option comparison
      if (isOptionComparison) {
        // check any option exactly equals
        if (equals([answer], comparisonValue)) {
          isAnyAnswerContainsValue = true
        }
      // text comparison, contains
      } else {
        // check field contains
        if (String(answer).indexOf(String(comparisonValue)) !== -1) {
          isAnyAnswerContainsValue = true
        }
      }
    })
    return isAnyAnswerContainsValue
  }
  return false
}

const more = (answers, comparisonValue) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerLessOrEqualThanValue = false
    answers.forEach(answer => {
      if (!isValidNumber(answer) || (answer - comparisonValue) <= 0) {
        isAnyAnswerLessOrEqualThanValue = true
      }
    })
    return !isAnyAnswerLessOrEqualThanValue
  }
  return false
}

const less = (answers, comparisonValue) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerMoreOrEqualThanValue = false
    answers.forEach(answer => {
      if (!isValidNumber(answer) || (answer - comparisonValue) >= 0) {
        isAnyAnswerMoreOrEqualThanValue = true
      }
    })
    return !isAnyAnswerMoreOrEqualThanValue
  }
  return false
}

const moreOrEquals = (answers, comparisonValue) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerLessThanValue = false
    answers.forEach(answer => {
      if (!isValidNumber(answer) || (answer - comparisonValue) < 0) {
        isAnyAnswerLessThanValue = true
      }
    })
    return !isAnyAnswerLessThanValue
  }
  return false
}

const lessOrEquals = (answers, comparisonValue) => {
  if (answers && answers.length > 0) {
    let isAnyAnswerMoreThanValue = false
    answers.forEach(answer => {
      if (!isValidNumber(answer) || (answer - comparisonValue) > 0) {
        isAnyAnswerMoreThanValue = true
      }
    })
    return !isAnyAnswerMoreThanValue
  }
  return false
}

const isNull = (answers) => (
  !isNotNull(answers)
)

const isNotNull = (answers) => {
  let isNotNull = false
  if (answers && answers.length > 0) {
    answers.forEach(answer => {
      if (
        // not undefined
        answer &&
        // not null
        !_.isNull(answer) &&
        // not spaces, tabs or line breaks
        !!String(answer).replace(/\s/g, '').length) {
        isNotNull = true
      }
    })
  }
  return isNotNull
}

const count = (answers, occurrenceCount, comparisonValue, subjectType, subjectValue, template) => {
  const deepCloneAnswers = _.cloneDeep(answers)
  const deepCloneTemplate = _.cloneDeep(template)

  if (deepCloneAnswers && deepCloneAnswers.length >= 0 && template) {
    // for performance, skip the logic if the answers is less than comparisonValue
    var matchedAnswers = deepCloneAnswers.filter(a => a.answerText === comparisonValue)
    if (matchedAnswers && matchedAnswers.length >= occurrenceCount) {
      if (subjectType.toUpperCase() === ElementKindType.Section.toUpperCase()) {
        let section = {}
        // search the hierarchy tree to find the section.
        visitTreeInPreOrder(deepCloneTemplate, ({ node }) => {
          if (node && node.kind && node.kind.toUpperCase() === ElementKindType.Section.toUpperCase() &&
            node.id === subjectValue) {
            section = node
            return true
          }
          return false
        }, 'elements', true)
        if (section && section.id) {
          let answerCount = 0
          visitTreeInPreOrder(section, ({ node }) => {
            if (node && node.kind && node.kind.toUpperCase() === ElementKindType.Question.toUpperCase()) {
              const questionAnswers = getAnswersForSingleQuestion(deepCloneAnswers, node.id)
              questionAnswers.forEach(a => {
                if (a.answerText === comparisonValue) {
                  answerCount++
                }
              })
            }
          }, 'elements')

          if (answerCount === occurrenceCount) {
            return true
          }
        }
      }
      // the backend contract only support seciton at the moment. extend here when the requirement grows.
    }
  }
  return false
}

export const isCriterionValid = (
  answers,
  operation,
  comparisonValue,
  isOptionComparison,
  subjectType,
  subjectValue,
  template,
  occurrenceCount) => {
  // map dto answers to pure answer value array
  const answerValues = getAnswersValue(answers)

  switch (operation) {
    case CriterionOperationEnum.IsNotNull : {
      return isNotNull(answerValues)
    }
    case CriterionOperationEnum.IsNull : {
      return isNull(answerValues)
    }
    case CriterionOperationEnum.Equals : {
      return equals(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.NotEquals : {
      return notEquals(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.Contains : {
      return contains(answerValues, comparisonValue, isOptionComparison)
    }
    case CriterionOperationEnum.More : {
      return more(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.Less : {
      return less(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.MoreOrEquals : {
      return moreOrEquals(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.LessOrEquals : {
      return lessOrEquals(answerValues, comparisonValue)
    }
    case CriterionOperationEnum.Count : {
      return count(answers, occurrenceCount, comparisonValue, subjectType, subjectValue, template)
    }
    default: {
      return false
    }
  }
}
