import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : '/register/:id',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
      dependencies for bundling   */
      const RegistryScreenContainer = require('./containers/RegistryScreenContainer').default
      const reducer = require('./modules/register').default
      injectReducer(store, { key: 'register', reducer })
      // DO not need to inject the reducer as we use React hooks
      cb(null, RegistryScreenContainer)
      /* Webpack named bundle   */
    }, 'register')
  }
})
