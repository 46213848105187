import API from '../api'
// ------------------------------------
// Constants
// ------------------------------------
export const SYSTEM_PREFERENCES_RETRIEVING = 'SYSTEM_PREFERENCES_RETRIEVING'
export const SYSTEM_PREFERENCES_RETRIEVED = 'SYSTEM_PREFERENCES_RETRIEVED'
export const SYSTEM_PREFERENCES_ERROR = 'SYSTEM_PREFERENCES_ERROR'

// ------------------------------------
// Actions
// ------------------------------------

export function retrieveSystemPreferences () {
  return function (dispatch) {
    const endPoint = `/api/platform/preferences/system`
    API.getJson(endPoint)
      .then(data => {
        dispatch({
          type: SYSTEM_PREFERENCES_RETRIEVED,
          payload: data.systemPreferences
        })
      })
      .catch(error => {
        API.handleError(error, dispatch)
      })
  }
}

function stringToBoolean (val) {
  var bool = {
    'true': true,
    'false': false
  }
  return bool[val]
}

const ACTION_HANDLERS = {
  [SYSTEM_PREFERENCES_RETRIEVED]: (state, action) => {
    const isGlobalAttachmentsEnabled = stringToBoolean(action.payload['global.attachments.enabled']) || false
    const isGlobalLinkifyEnabled = stringToBoolean(action.payload['global.linkify.enabled']) || false
    const isExternalUserEnabled = stringToBoolean(action.payload['global.external.user.enabled']) || false
    const isGlobalRegisterDisabled = stringToBoolean(action.payload['global.register.disabled']) || false
    const isGlobalMiDashboardEnabled = stringToBoolean(action.payload['global.mi.dashboard']) || false
    const isGlobalMiDashboardEnabledForXu = stringToBoolean(action.payload['global.mi.dashboard.xu']) || false
    const isGlobalObserverDashboardEnabled = stringToBoolean(action.payload['global.observer.dashboard']) || false
    const isGlobalObserverRoleEnabled = stringToBoolean(action.payload['global.observer.role.enabled']) || false
    return Object.assign({}, state, {
      isGlobalAttachmentsEnabled,
      isGlobalLinkifyEnabled,
      isExternalUserEnabled,
      isGlobalRegisterDisabled,
      isGlobalMiDashboardEnabled,
      isGlobalObserverDashboardEnabled,
      isGlobalObserverRoleEnabled,
      isGlobalMiDashboardEnabledForXu
    })
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isGlobalAttachmentsEnabled: false,
  isGlobalLinkifyEnabled: false,
  isExternalUserEnabled: false,
  isGlobalRegisterDisabled: false,
  isGlobalMiDashboardEnabled: false,
  isGlobalObserverDashboardEnabled: false,
  isGlobalObserverRoleEnabled: false,
  isGlobalMiDashboardEnabledForXu: false
}

export default function systemPreferencesReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
