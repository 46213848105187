
export const CriterionOperationEnum = Object.freeze(
  {
    Equals: 'Equals',
    NotEquals: 'NotEquals',
    Contains : 'Contains',
    More: 'More',
    Less: 'Less',
    MoreOrEquals: 'MoreOrEquals',
    LessOrEquals: 'LessOrEquals',
    IsNull: 'IsNull',
    IsNotNull: 'IsNotNull',
    Count: 'Count'
  })

export const CountOperatorsEnum = Object.freeze(
  {
    Less: 'Less',
    LessOrEquals: 'LessOrEquals',
    Equals: 'Equals',
    MoreOrEquals: 'MoreOrEquals',
    More: 'More'
  })

export const OperandEnum = Object.freeze(
  {
    And: 'And',
    Or: 'Or',
    Count: 'Count'
  })

export const UnaryEnum = Object.freeze(
  {
    Invert: 'Invert',
    Nop: 'Nop'
  })
