
import Criterion from './criterion'
import Group from './group'

export const LogicSupplierNodeKindType = Object.freeze(
  {
    Group: 'Group',
    Criterion: 'Criterion'
  })

const LogicSupplierNodeKindMapping = Object.freeze({
  [LogicSupplierNodeKindType.Group] : Group,
  [LogicSupplierNodeKindType.Criterion] : Criterion
})

const LogicSupplierNodeKindFactory = (nodeKindType) => (
  LogicSupplierNodeKindMapping[nodeKindType]
)

export default LogicSupplierNodeKindFactory
