
export const QuestionType = Object.freeze(
  {
    Text: 'TEXT',
    Guidance: 'GUIDANCE',
    Date : 'DATE_CHOICE',
    RadioButtons: 'MULTIPLE_CHOICE',
    DropdownMenu: 'DROPDOWN_MENU',
    MultiCheckBoxesQuestion: 'MULTIPLE_CHOICE_CHECKBOX',
    MultipleSelect: 'MULTISELECT_LIST',
    TEXTAREA: 'TEXTAREA',
    SingleUser: 'SINGLE_USER',
    MultipleUser: 'MULTIPLE_USER'
  })

// backend not allow to push this yet?? need to confirm, use
export const ElementKindType = Object.freeze(
  {
    Question: 'Question',
    Section: 'Section'
  })

export const ElementType = Object.freeze(
  {
    Question: 'Question',
    Section: 'Section'
  })

export const QuestionTextType = Object.freeze({
  InputBoxSmall: 'SmallTextbox',
  InputBoxMedium: 'MediumTextbox',
  InputBoxLarge: 'LargeTextbox'
})
