import React from 'react'
import PropTypes from 'prop-types'

import { showNotification, dismissNotification } from '../store/shared'
import { AlertList } from 'react-bs-notifier'
import { connect } from 'react-redux'

const mapDispatchToProps = {
  showNotification,
  dismissNotification
}

const mapStateToProps = (state, ownProps) => ({
  notifications: state.shared.notifications
})

export const withNotificationOnly = (BaseComponent) => {
  const WrappedComponent = (props) => {
    return (
      <div>
        <AlertList
          position='top-right'
          alerts={props.notifications || []}
          timeout={3000}
          dismissTitle='Dismiss'
          onDismiss={props.dismissNotification}
        />
        <BaseComponent {...props} />
      </div>
    )
  }

  WrappedComponent.propTypes = {
    notifications: PropTypes.any,
    dismissNotification: PropTypes.func
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}

export default withNotificationOnly
