import createDOMPurify from 'dompurify'
import { convertHtmlEntityToAngleBrackets } from '../routes/CaptureForm/modules/answer'

const DOMPurify = createDOMPurify(window)
const allowedTags = {
  ALLOWED_TAGS: [
    'b',
    'blockquote',
    'br',
    'div',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'li',
    'ol',
    'p',
    'pre',
    'section',
    'span',
    'strike',
    'strong',
    'sub',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'ul'
  ] }
export function sanitized (value) {
  const sanitizedValue = DOMPurify.sanitize(value, allowedTags)
  return convertHtmlEntityToAngleBrackets(sanitizedValue)
}
