import React from 'react'
import PropTypes from 'prop-types'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import LogoImage from './rfalogo-white-20px-high.png'
import './ConcurNavbar.scss'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

export const ConcurNavbar = React.memo(function ConcurNavbar ({ currentUser, groupNavItems }) {
  return <Navbar>
    <Navbar.Header>
      <Navbar.Brand>
        <img alt='Brand' height='20px' src={LogoImage} />
      </Navbar.Brand>
    </Navbar.Header>

    {currentUser && currentUser.status === 'Active' &&
      groupNavItems.map((groupItem, groupIndex) => {
        if (groupItem.isNavDropdown) {
          return (
            <Nav key={groupIndex}>
              <NavDropdown
                id='navDropdown'
                active={groupItem.isActive} title={groupItem.title} className={groupItem.className}>
                {
                  groupItem.items.map((item, index) => {
                    return (
                      <MenuItem key={index} href={item.link}>{item.text}</MenuItem>
                    )
                  })
                }
              </NavDropdown>
            </Nav>
          )
        } else {
          return (
            groupItem.items.map((item, index) => {
              return (
                <Nav key={index}>
                  <NavItem active={item.isActive} eventKey={index + 1} href={item.link}>
                    {item.text}
                  </NavItem>
                </Nav>
              )
            })
          )
        }
      })
    }

    {currentUser &&
      <Navbar.Text pullRight>
        {`${currentUser.name || ''} ${currentUser.surname || ''}`}
      </Navbar.Text>
    }
  </Navbar>
})

ConcurNavbar.propTypes = {
  currentUser: PropTypes.object,
  groupNavItems: PropTypes.array
}

const getCurrentUser = state => state.auth.user
const getIsExternalUser = state => state.auth.user && state.auth.user.isExternalUser
const getCurrentEntitlements = state =>
  (state.auth.user && state.auth.user.entitlements) ? state.auth.user.entitlements : {}
const getLocation = state => state.location
const getDashboard = state => state.dashboard
const getReviewDetails = state => state.reviewDetails
const getConfig = state => state.config
const getIsExternalUserEnabled = state => state.systemPreferences.isExternalUserEnabled
const getIsGlobalRegisterDisabled = state => state.systemPreferences.isGlobalRegisterDisabled
const getIsGlobalMiDashboardEnabled = state => state.systemPreferences.isGlobalMiDashboardEnabled
const getIsGlobalObserverRoleEnabled = state => state.systemPreferences.isGlobalObserverRoleEnabled

const getBreadcrumbItems = createSelector(
  [getCurrentUser,
    getCurrentEntitlements,
    getDashboard, getReviewDetails,
    getConfig,
    getIsGlobalRegisterDisabled],
  (currentUser,
    entitlements,
    dashboard,
    reviewDetails,
    config,
    isGlobalRegisterDisabled) => {
    let breadcrumbItems = [{
      text: 'New Forms',
      link: '/capture-new-form-list',
      isActive: location.pathname === '/capture-new-form-list'
    }]

    if (!isGlobalRegisterDisabled) {
      breadcrumbItems.push({
        text: 'Register',
        link: '/register-list',
        isActive: location.pathname === '/register-list'
      })
    }

    if (dashboard) {
      breadcrumbItems.push({
        text: 'Dashboard',
        link: '#',
        isActive: true
      })
    } else if (reviewDetails) {
      const revisionInfo = reviewDetails.revisionInfo
      const revId = (revisionInfo && revisionInfo.current) || ''
      breadcrumbItems.push({
        text: 'Dashboard',
        link: `/dashboard/${reviewDetails.reviewId}?&revid=${revId}`
      }, {
        text: 'Details',
        link: '#',
        isActive: true
      })
    } else if (config) {
      breadcrumbItems.push({
        text: 'Configuration',
        link: '#',
        isActive: true
      })
    }
    return breadcrumbItems
  }
)

const getGroupNavItems = createSelector(
  [getCurrentUser, getCurrentEntitlements, getLocation, getBreadcrumbItems, getIsExternalUserEnabled,
    getIsExternalUser, getIsGlobalMiDashboardEnabled, getIsGlobalObserverRoleEnabled],
  (currentUser, entitlements, location, breadcrumbItems, isExternalUserEnabled, isExternalUser,
    isGlobalMiDashboardEnabled, isGlobalObserverRoleEnabled) => {
    const homeMenuTitle = 'Dashboard'
    const groupNavItems = []

    if ((isExternalUserEnabled || isGlobalMiDashboardEnabled || isGlobalObserverRoleEnabled) && !isExternalUser) {
      const homeNavSubItems = [
        {
          text: 'My Assessments',
          link: `/`
        },
        {
          text: 'My SmartForms',
          link: `/observer-capture-form-list`
        }
      ]

      groupNavItems.push({
        isNavDropdown: true,
        className: 'admin',
        title: homeMenuTitle,
        isActive: homeNavSubItems.some(item => item.link === location.pathname),
        items: homeNavSubItems
      })
    }

    if (isExternalUser || (!isExternalUserEnabled && !isGlobalMiDashboardEnabled && !isGlobalObserverRoleEnabled)) {
      groupNavItems.push({
        items: [
          {
            text: homeMenuTitle,
            link: '/',
            isActive: location.pathname === '/'
          }
        ]
      })
    }

    // BreadcrumbItems
    if (breadcrumbItems.length > 0 && !isExternalUser) {
      groupNavItems.push({
        items: breadcrumbItems
      })
    }

    // Admin NavItems
    const adminNavItems = []
    if (entitlements.canListAllCaptureTemplates) {
      adminNavItems.push(
        {
          text: 'SmartScript List',
          link: `/capture-template-list`
        })
    }
    if (entitlements.canListCaptureEmptyForms) {
      adminNavItems.push(
        {
          text: 'SmartForm List',
          link: `/capture-form-list`
        })
    }
    if (entitlements.canListUsers) {
      adminNavItems.push(
        {
          text: 'User List',
          link: `/user-list`
        })
    }
    if (entitlements.canListUserGroups) {
      adminNavItems.push(
        {
          text: 'User Group List',
          link: `/user-group-list`
        })
    }
    if (entitlements.canListUsers && isExternalUserEnabled) {
      adminNavItems.push(
        {
          text: 'External User List',
          link: `/external-user-list`
        })
    }
    if (entitlements.canListUserGroups && isExternalUserEnabled) {
      adminNavItems.push(
        {
          text: 'External User Group List',
          link: `/external-user-group-list`
        })
    }
    if (adminNavItems.length !== 0 && !isExternalUser) {
      groupNavItems.push({
        isNavDropdown: true,
        className: 'admin',
        title: 'Admin',
        isActive: adminNavItems.some(item => item.link === location.pathname),
        items: adminNavItems
      })
    }

    return groupNavItems
  }
)

export default connect(state => {
  return {
    currentUser: state.auth.user,
    groupNavItems: getGroupNavItems(state)
  }
})(ConcurNavbar)
