import API from '../api'
import { GetUserEntitlements } from 'models/userEntitlements'
import { checkIsUserAdmin } from '../routes/UserEditor/modules/userEditor'
// ------------------------------------
// Constants
// ------------------------------------
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const USER_ROLE_RETRIEVED = 'USER_ROLE_RETRIEVED'
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR'
export const CONFIG_RECEIVED = 'CONFIG_RECEIVED'

// ------------------------------------
// Actions
// ------------------------------------
export function getAuthToken () {
  return sessionStorage.getItem('tid')
}

export function setAuthToken (token) {
  return {
    type: SET_AUTH_TOKEN,
    payload: token
  }
}

function normalizeUser (response) {
  /* // debug mock data.
  response.entitlements = [
    'DIS_LIST_USR',
    'DIS_CRUD_USR',
    'DIS_LIST_UGP',
    'DIS_CRUD_UGP',
    'CAP_LIST_TEM',
    'CAP_CRUD_TEM',
    'CAP_LIST_FRM',
    'CAP_CRUD_FRM',
    'CAP_LIST_SUB',
    'CAP_EXPT_SUB',
    'CON_LIST_REV',
    'CON_DELG_REV',
    'CAP_LIST_REG',
    'CAP_CRUD_REG'
  ] */
  response.isUserAdmin = checkIsUserAdmin(response.entitlements)
  var userEntitlements = GetUserEntitlements(response)
  response.entitlements = userEntitlements
  return { ...response }
}

export function authenticateUser (user = '') {
  return (dispatch, getState) => {
    Promise.all([
      API.getJson('/api/concur/user'),
      API.getJson('/api/concur/config')
    ]).then(responses => {
      dispatch({
        type : USER_AUTHENTICATED,
        payload : normalizeUser(responses[0])
      })
      dispatch({
        type : CONFIG_RECEIVED,
        payload : responses[1]
      })
    }).catch((error) => {
      if (error.status === 401 || !error.headers) {
        location.href = error.headers.get('location')
      } else {
        API.handleError(error, dispatch)
      }
    })
  }
}

export function heartbeat () {
  return (dispatch, getState) => {
    API.call('/api/concur/user')
      .then(response => {
        if (!response.ok) {
          throw response
        }
      })
      .catch((error) => {
        API.handleError(error, dispatch)
      })
  }
}

const ACTION_HANDLERS = {
  [SET_AUTH_TOKEN]: (state, action) => {
    const authToken = action.payload || sessionStorage.getItem('tid')
    if (action.payload && action.payload !== sessionStorage.getItem('tid')) {
      // set the token, so that listening tabs receive it as an event
      window.localStorage.setItem('SENDING_SHARED_DATA', authToken)
      // and immediately remove it, so that it doesn't stay in the local storage
      window.localStorage.removeItem('SENDING_SHARED_DATA')
    }
    API.setAuthToken(authToken)
    return Object.assign({}, state, { authToken: authToken })
  },
  [USER_AUTHENTICATED]: (state, action) => {
    sessionStorage.setItem('tid', state.authToken)
    return Object.assign({}, state, {
      user: action.payload,
      isAuthenticating: false
    })
  },
  [AUTH_ERROR]: (state, action) => {
    return Object.assign({}, state, {
      authError: action.payload,
      isAuthenticating: false
    })
  },
  [CONFIG_RECEIVED]: (state, action) => {
    return Object.assign({}, state, {
      config: action.payload
    })
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthenticating: true,
  isRetrievingRole: true,
  authError: '',
  authToken: '',
  user: null,
  config: null
}
export default function authReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
