export { UserRole } from '../hoc/userRoleProps'

export const OrdinalNums = ['1st', '2nd', '3rd', '4th']

export const SectionType = {
  Approval: 'Approval'
}

export const QuestionType = {
  TEXTAREA: 'TEXTAREA',
  DATE_CHOICE: 'DATE_CHOICE',
  MULTISELECT_LIST: 'MULTISELECT_LIST',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  DROPDOWN_MENU: 'DROPDOWN_MENU',
  MULTIPLE_CHOICE_CHECKBOX: 'MULTIPLE_CHOICE_CHECKBOX',
  GUIDANCE: 'GUIDANCE',
  SINGLE_USER: 'SINGLE_USER',
  MULTIPLE_USER: 'MULTIPLE_USER'
}

export const ReviewStatus = {
  Inactive: 'Inactive',
  ReviewCompleted: 'ReviewCompleted',
  PendingReview: 'PendingReview',
  PendingApprovals: 'PendingApprovals',
  PendingRevision: 'PendingRevision',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Finished: 'Finished',
  Cancelled: 'Cancelled',
  Closed: 'Closed'
}

export const UserStatus = {
  Open: 'Open',
  Delegated: 'Delegated',
  Submitted: 'Submitted',
  Closed: 'Closed',
  NotActivated: 'NotActivated'
}

// Review status from the user's perspective
export const ConcurUserReviewStatus = {
  [ReviewStatus.Inactive] : 'Draft',
  [ReviewStatus.PendingReview] : 'Pending Reviews',
  [ReviewStatus.PendingRevision] : 'Pending Revision',
  [ReviewStatus.ReviewCompleted] : 'Pending Owner Response',
  [ReviewStatus.Finished] : 'Complete',
  [ReviewStatus.PendingApprovals] : 'Pending Approval',
  [ReviewStatus.Approved] : 'Approved',
  [ReviewStatus.Rejected] : 'Rejected',
  [ReviewStatus.Cancelled] : 'Cancelled',
  [ReviewStatus.Closed]: 'Closed'
}

export const ReviewAllocationResponseType = {
  Optional: 'Optional',
  Required: 'Required',
  Disabled: 'Disabled'
}

export const DefaultAllocationConfig = {
  viewRequired: true,
  response: 'Optional'
}

export const ReviewerQuestionConfigLabel = {
  Concur: 'I Concur',
  DoNotConcur: 'I Do Not Concur',
  Neutral: 'NEUTRAL / NOT APPLICABLE'
}

export const ConfigCommentType = {
  Optional: 'optional',
  Required: 'required',
  None: 'none'
}

export const DefaultApprovalQuestionConfig = {
  questionText: 'What is your response?',
  answers: [
    {
      label: 'APPROVE',
      text: 'I Approve',
      isMandatory: true,
      isSelected: true,
      color: '#44b31f',
      commentType: 'optional'
    },
    {
      label: 'REOPEN',
      text: 'Reopen',
      isMandatory: false,
      isSelected: true,
      color: '#f9a305',
      commentType: 'required'
    },
    {
      label: 'REJECT',
      text: 'Reject',
      isMandatory: false,
      isSelected: false,
      color: '#cd0b24',
      commentType: 'required'
    }
  ]
}

export const DefaultReviewQuestionConfig = {
  questionText: 'What is your response to the answer provided?',
  answers: [
    {
      label: ReviewerQuestionConfigLabel.Concur,
      text: 'I Concur',
      isMandatory: true,
      isSelected: true,
      color: '#44b31f',
      commentType: 'optional'
    },
    {
      label: ReviewerQuestionConfigLabel.DoNotConcur,
      text: 'I do not Concur',
      isMandatory: false,
      isSelected: true,
      color: '#cd0b24',
      commentType: 'required'
    },
    {
      label: ReviewerQuestionConfigLabel.Neutral,
      text: 'Neutral / Not Applicable',
      isMandatory: false,
      isSelected: false,
      color: '#999999',
      commentType: 'none'
    }
  ]
}

export const DefaultDelegationConfig = {
  allowToDelegate: false
}

export const TabType = {
  Discuss: 'discuss',
  Attachment: 'attachment',
  Reference: 'reference'
}

export const UserEntitlement = {
  canListUsers: 'DIS_LIST_USR',
  canCrudUserEntity: 'DIS_CRUD_USR',
  canListUserGroups: 'DIS_LIST_UGP',
  canCrudUserGroups: 'DIS_CRUD_UGP',
  canListAllCaptureTemplates: 'CAP_LIST_TEM',
  canCrudCaptureTemplates: 'CAP_CRUD_TEM',
  canListCaptureEmptyForms: 'CAP_LIST_FRM',
  canCrudCaptureEmptyFroms: 'CAP_CRUD_FRM',
  canListSubmissions: 'CAP_LIST_SUB',
  canExportSubmission: 'CAP_EXPT_SUB',
  canListConcurReviews: 'CON_LIST_REV',
  canDelegateRoleForConcurReview: 'CON_DELG_REV',
  canSeeRegisterRowData: 'CAP_LIST_REG',
  canConfigureRegisters: 'CAP_CRUD_REG'
}

export const DefaultEmptyObject = {}
export const DefaultEmptyArray = []

export const DelegationHistoryRoleReplacement = {
  RESPONDENT: 'RESPONDENT',
  Owner: 'Owner'
}

export const DelegateFromRoles = {
  Owner: 'Owner',
  Respondent: 'Respondent',
  Approver: 'Approver'
}

export const AllColumnsFilter = {
  title: 'All Columns',
  id: 'ALL_COLUMNS_FILTER_ID'
}

export const ApprovalQuestionOptions = {
  IApprove: 'I Approve',
  Reopen: 'Reopen',
  Reject: 'Reject'
}

export const AdminPathToBeBlockedForXu = [
  'capture-template-list',
  'capture-template/:templateId',
  'capture-form-list',
  'capture-form-editor/(:mode)/(:formId)',
  'form-submission-list/:formId',
  'review-list',
  'user-list',
  'user-editor/(:mode)/(:userId)',
  'user-group-list',
  'user-group-editor/(:mode)/(:userGroupId)',
  'register-editor/(:mode)/(:registerId)',
  '/register-list',
  '/register/:id',
  'capture-new-form-list',
  'observer-capture-form-list',
  'external-user-list',
  'external-user-group-list',
  'external-user-editor/(:mode)/(:userId)',
  'external-user-group-editor/(:mode)/(:userGroupId)',
  'detail/:reviewId/(:questionId)(/:action)'
]

export const AdminPathToBeBlockedForNoneAdminAndObservers = [
  'capture-template-list',
  'capture-template/:templateId',
  'capture-form-list',
  'capture-form-editor/(:mode)/(:formId)',
  'user-list',
  'user-editor/(:mode)/(:userId)',
  'user-group-list',
  'user-group-editor/(:mode)/(:userGroupId)',
  'register-editor/(:mode)/(:registerId)'
]
export const defaultConcurReviewQuestion = {
  questionText: 'What is your response to this assessment?'
}
