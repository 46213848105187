import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/Header'
import ConcurNavbar from '../../containers/ConcurNavbar'
import './CoreLayout.scss'
import '../../styles/core.scss'
import pubsub from 'models/pubsub'

const HeaderNames = {
  '/review-config': 'ADD NEW CONCUR REVIEW',
  '/dashboard': 'CONCUR REVIEW DASHBOARD',
  '/review-list': 'SmartForm<sup>TM</sup>: <b>Reviews</b>',
  '/capture-form-list': '<b>SmartForms List</b>',
  '/form-submission-list': 'SmartForms submissions',
  '/home': 'Dashboard: <b>My Assessments</b>',
  '/register-list': '<b>Registers List</b>',
  '/register': 'Register',
  '/capture-new-form-list': 'Start a new <b>SmartForm<sup>TM</sup> submission</b>',
  '/capture-template-list': '<b>SmartScripts List<b>',
  '/capture-template': 'SmartScript<sup>TM</sup> Editor:',
  '/capture-form-editor/new': 'Configure SmartForm<sup>TM</sup>',
  '/capture-form-editor/edit': 'Configure SmartForm<sup>TM</sup>',
  '/capture-form': 'SmartForm<sup>TM</sup>',
  '/user-list': '<b>User List</b>',
  '/user-group-list': '<b>User Group List</b>',
  '/user-group-editor': 'Configure User Group:',
  '/capture-submission-report': 'SmartForm<sup>TM</sup>',
  '/user-editor': 'Config User:',
  '/observer-capture-form-list': 'Dashboard: <b>My SmartForms List</b>',
  '/external-user-group-list': '<b>External User Group List</b>',
  '/external-user-group-editor': '<b>Configure External User Group: </b>',
  '/external-user-editor': '<b>Configure External User: </b>',
  '/external-user-list': '<b>External User List</b>'
}
const captureHeaderNames = [
  '/home',
  '/register-list',
  '/register',
  '/capture-new-form-list',
  '/capture-template-list',
  '/capture-template',
  '/capture-form-editor/',
  '/capture-form',
  '/form-submission-list',
  '/user-list',
  '/user-group-list',
  '/user-group-editor',
  '/capture-submission-report',
  '/user-editor',
  '/review-list',
  '/observer-capture-form-list',
  '/external-user-group-list',
  '/external-user-group-editor',
  '/external-user-editor',
  '/external-user-list'

]

function getPageClassNames (pathname) {
  if (!pathname) {
    return ''
  }
  pathname = pathname.toLowerCase()
  return pathname.split('/').filter(s => !!s)[0]
}

function getHeaderName (pathName) {
  for (const k in HeaderNames) {
    if (pathName.indexOf(k) >= 0) {
      return HeaderNames[k]
    }
  }
  return ''
}

function getPathName (location) {
  let pathname = location.pathname
  if (pathname && pathname[pathname.length - 1] === '/') {
    pathname = pathname.substring(0, pathname.length - 1)
  }
  return pathname || '/home'
}

export const CoreLayout = ({ location, children }) => {
  const pathname = getPathName(location)
  const [headerName, setHeaderName] = useState('')
  const [headerRest, setHeaderRest] = useState()

  useEffect(() => {
    setHeaderName(getHeaderName(pathname))
    pubsub.subscribe('title-bar-content', (e) => {
      setHeaderName(e.data)
    })
    pubsub.subscribe('title-bar-rest', (e) => {
      setHeaderRest(e.data)
    })
  }, [])

  useEffect(() => setHeaderRest(), [pathname])

  const isLoginPage = location.pathname.indexOf('/login') >= 0
  if (isLoginPage) {
    return (
      <div className='login-container'>
        {children}
      </div>
    )
  }
  function getBackgroundColors (pathname) {
    for (const element of captureHeaderNames) {
      if (pathname.indexOf(element) !== -1) {
        return '#fff'
      }
    }
    return '#f5f5f5'
  }
  const bgColor = getBackgroundColors(pathname)
  return (
    <div className={getPageClassNames(pathname)}
      style={{ height: '100%', backgroundColor: bgColor }}>
      <div style={{ clear: 'both', backgroundColor: bgColor }}>
        <ConcurNavbar />
        <Header name={headerName} headerRest={headerRest} />
        <div className='container'>
          <div className='core-layout__viewport'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any
}

export default CoreLayout
