import { flattenUsers, isUserDelegated } from './user'
import { ReviewAllocationResponseType, DefaultAllocationConfig } from './constants'

export function getReviewers (reviewConfig, userStatuses) {
  reviewConfig = reviewConfig || {}
  return flattenUsers(reviewConfig.reviewers || [])
    .filter(u => userStatuses[u.id] && !isUserDelegated(u, userStatuses))
}

export function getQuestionAllocationMapForReviewer (reviewer, reviewConfig) {
  const questionAllocations = (reviewConfig.allocations || []).filter(
    allocation => allocation.userId === reviewer.id
  )
  const questionAllocationMap = {}
  questionAllocations.forEach(allocation => {
    if (!allocation.config) {
      return
    }
    allocation.config.forEach(qc => {
      questionAllocationMap[qc.questionId] = qc
    })
  })
  return questionAllocationMap
}

// Approvers don't have allocations, return responseRequired for all the approval questions
export function getQuestionAllocationMapForApprover (approver, approvalQuestions) {
  if (!approvalQuestions || !approver) {
    return {}
  }
  const map = {}
  approvalQuestions.forEach(q => {
    if (q.approverId === approver.id) {
      map[q.id] = {
        viewRequired: true,
        response: ReviewAllocationResponseType.Required
      }
    } else {
      map[q.id] = DefaultAllocationConfig
    }
  })

  return map
}
