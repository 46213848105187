export function deepClone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isFeatureEnabled (userConfig, featureName) {
  return userConfig && userConfig.features && userConfig.features[featureName]
}

export function isInViewport (elem) {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
