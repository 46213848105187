import { getAllDisplayQuestionIds } from './question'
import _ from 'lodash'
import uuid from 'models/uuid'

const removeAnswersForSingleQuestion = (answersBasement, questionId) => {
  if (!!answersBasement && answersBasement.length > 0) {
    return answersBasement.filter(answer => {
      if (answer.questionId !== questionId) {
        return answer
      }
    })
  }
  return []
}

export const updateSingleQuestionAnswers = (singleQuestionAnswers, answersBasement, questionId) => {
  let filteredAnswers = removeAnswersForSingleQuestion(
    answersBasement,
    questionId)
  if (!!singleQuestionAnswers && singleQuestionAnswers.length > 0) {
    return [...filteredAnswers, ...singleQuestionAnswers]
  }
  return filteredAnswers
}

export const getAnswersForSingleQuestion = (answers, questionId) => {
  if (!!answers && answers.length > 0) {
    return answers.filter(answer => {
      if (answer.questionId === questionId) {
        return answer
      }
    })
  }
  return []
}

export const getTextQuestionAnswer = (answers, questionId) => {
  const questionAnswers = getAnswersForSingleQuestion(answers, questionId)
  if (questionAnswers && questionAnswers.length > 0) {
    const answerText = questionAnswers[0].answerText
    return (answerText === null || answerText === undefined) ? '' : answerText
  }
  return ''
}

export const getAnswersForCurrentDisplayQuestions = (
  answers,
  answersRepo,
  currentDisplayQuestionIds) => {
  currentDisplayQuestionIds = currentDisplayQuestionIds || []

  // clean answer that question is disappeared
  let displayAnswers = answers.filter(a => {
    if (currentDisplayQuestionIds.indexOf(a.questionId) !== -1) {
      return a
    }
  })

  const displayAnswerQuestionIds = displayAnswers.map(a => a.questionId)

  let operatedQuesitonIds = []
  // add answer that question is appeared.
  answersRepo.forEach(a => {
    if (
    // current diaplay question exists.
      currentDisplayQuestionIds.indexOf(a.questionId) !== -1 &&
      // same multi choise answer not operated.
       !(operatedQuesitonIds.indexOf(a.questionId) !== -1) &&
      // not exists in the current answers, then restore the cache.
       !(displayAnswerQuestionIds.indexOf(a.questionId) !== -1)) {
      const operatedAnswers = getAnswersForSingleQuestion(answersRepo, a.questionId)
      operatedAnswers.forEach(operateAnswer => {
        displayAnswers.push(operateAnswer)
      })
    }
  })

  return displayAnswers
}

export const getAnswersValue = (answers) => {
  let answersText = []
  if (answers && answers.length > 0) {
    answersText = answers.map(a => {
      if (a.answerOption === undefined) {
        return a.answerText
      }
      return a.answerOption
    })
  }
  return answersText
}

export const getAnswerByQuestionAndId = (answers, questionId, id, optionValue) => {
  if (answers && answers.length > 0) {
    const filteredAnswer = answers.filter(answer => {
      if (answer.answerOption) {
        if (answer.answerOption === optionValue && answer.questionId === questionId) {
          return answer
        }
      } else {
        if (answer.id === id && answer.questionId === questionId) {
          return answer
        }
      }
    })

    return (filteredAnswer && filteredAnswer.length > 0) ? filteredAnswer[0] : {}
  }
  return {}
}

export const getAnswerByOption = (answers, optionValue) => {
  const filteredAnswer = answers.filter(answer => {
    if (answer.answerOption === optionValue) {
      return answer
    }
  })

  return (filteredAnswer && filteredAnswer.length > 0) ? filteredAnswer[0] : {}
}

export const isAnswerAnswered = (answer) => {
  return answer &&
  (!!answer.answerText ||
    !!answer.answerOption)
}

export const isAnswerCommentAnswered = (answer) => {
  return answer &&
    !!answer.answerComment &&
    answer.answerComment.length > 0
}

export const isQuestionAnswered = (answers, questionId) => {
  const questionAnswers = getAnswersForSingleQuestion(answers, questionId)
  return questionAnswers && questionAnswers.some(a => isAnswerAnswered(a))
}

export const getCaculatedAnswers = (questionsBase, answers, answersRepo, template) => {
  const originalAnswerQuestionIds = answers.map(a => a.questionId)
  // current display questions after that single answer change.
  const questionIds = getAllDisplayQuestionIds(questionsBase, answers, template)

  const updatedAnswers =
      getAnswersForCurrentDisplayQuestions(
        answers,
        answersRepo,
        questionIds)

  const updatedAnswerQuestionIds = updatedAnswers.map(a => a.questionId)

  // check if any new question answer get added or removed.
  let isAnswersChanged = false
  for (var updatedAnswer of updatedAnswers) {
    if (!(originalAnswerQuestionIds.indexOf(updatedAnswer.questionId) !== -1)) {
      isAnswersChanged = true
      break
    }
  }

  for (var answer of answers) {
    if (!(updatedAnswerQuestionIds.indexOf(answer.questionId) !== -1)) {
      isAnswersChanged = true
      break
    }
  }

  if (isAnswersChanged) {
    return getCaculatedAnswers(questionsBase, updatedAnswers, answersRepo, template)
  } else {
    return updatedAnswers
  }
}

export const convertHtmlEntityToAngleBrackets = (answerText) => {
  const htmlEntities = /&(lt|gt);/
  const isHtmlEntityUsed = htmlEntities.test(answerText)
  if (isHtmlEntityUsed) {
    return answerText.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  } else {
    return answerText
  }
}

export const convertAngleBracketsToHtmlEntity = (a) => {
  const answerText = a.answerText || ''
  const angleBrackets = /<|>/
  const isAngleBracketsUsed = angleBrackets.test(answerText)
  if (isAngleBracketsUsed) {
    a.answerText = answerText.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  }
}

export const mapToAnswersDto = (answers, questionId) => {
  answers = answers || []
  answers.forEach(a => {
    if (!a.id) {
      a.id = uuid()
    }
    convertAngleBracketsToHtmlEntity(a)
  })

  if (answers.length === 0) {
    answers = [
      {
        id: uuid(),
        questionId: questionId
      }
    ]
  }

  var questions = _.groupBy(answers, 'questionId')

  const answersDto = {
    answers : {
      ...questions
    }
  }
  return answersDto
}

export const getConfirmationAnswer = (answers, confirmationQuestionId) => {
  let confirmationAnswer = {
    id: uuid(),
    questionId: confirmationQuestionId
  }
  if (confirmationQuestionId && answers && answers.length > 0) {
    for (var key in answers) {
      if (answers[key].questionId === confirmationQuestionId) {
        confirmationAnswer = answers[key]
      }
    }
  }
  return confirmationAnswer
}

export function getQuestionAnswer (answers, questionId) {
  const questionAnswers = getAnswersForSingleQuestion(answers, questionId)
  if (questionAnswers && questionAnswers.length > 0) {
    return questionAnswers[0]
  }
  return {}
}

export function getUserFromAnswer (answer, allUsers) {
  let user = {}
  if (answer.answerText && allUsers && allUsers.length > 0) {
    const userId = answer.answerText
    user = allUsers.find(user => user.id === userId)
  }
  return user
}
