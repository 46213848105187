export default () => ({
  path : '/register-list',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
        dependencies for bundling   */
      const RegistryListContainer = require('./containers/RegistryListContainer').default

      // DO not need to inject the reducer as we use React hooks
      cb(null, RegistryListContainer)
      /* Webpack named bundle   */
    }, 'register-list')
  }
})
