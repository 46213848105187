import uuid from './uuid'
import { QuestionType } from './constants'
export const SECTION_TYPE = {
  QUESTIONS: 'Questions',
  HTML_INFO_PAGE: 'HTMLInfoPage',
  CONFIRMATION: 'Confirmation'
}

export const TEMPLATE_NODE_TYPE = {
  ROOT: 'Root',
  SECTION: 'Section',
  QUESTION: 'Question',
  UNKNOWN: 'Unknown'
}

const MultiChoiceQuestionTypes = [
  QuestionType.MULTIPLE_CHOICE, QuestionType.MULTIPLE_CHOICE_CHECKBOX,
  QuestionType.DROPDOWN_MENU, QuestionType.MULTISELECT_LIST
]

const UserQuestionTypes = [
  QuestionType.MULTIPLE_USER, QuestionType.SINGLE_USER
]

export function isMultiChoiceQuestionType (questionType) {
  return MultiChoiceQuestionTypes.indexOf(questionType) >= 0
}

export function isUserQuestionType (questionType) {
  return UserQuestionTypes.indexOf(questionType) >= 0
}

export function isTextQuestionType (questionType) {
  return questionType === QuestionType.TEXTAREA
}

export function isDateQuestionType (questionType) {
  return questionType === QuestionType.DATE_CHOICE
}

export function isQuestionNode (templateNode) {
  return getTemplateNodeType(templateNode) === TEMPLATE_NODE_TYPE.QUESTION
}

export function isSectionNode (templateNode) {
  return getTemplateNodeType(templateNode) === TEMPLATE_NODE_TYPE.SECTION
}

export function isConfirmationSection (templateNode) {
  return getTemplateNodeType(templateNode) === TEMPLATE_NODE_TYPE.SECTION &&
    templateNode.type === SECTION_TYPE.CONFIRMATION
}

export function isHtmlInfoSection (templateNode) {
  return getTemplateNodeType(templateNode) === TEMPLATE_NODE_TYPE.SECTION &&
    templateNode.type === SECTION_TYPE.HTML_INFO_PAGE
}

export function isRootNode (templateNode) {
  return getTemplateNodeType(templateNode) === TEMPLATE_NODE_TYPE.ROOT
}

export function getTemplateNodeType (templateNode) {
  if (!templateNode) {
    return TEMPLATE_NODE_TYPE.UNKNOWN
  }
  if (templateNode.status) {
    return TEMPLATE_NODE_TYPE.ROOT
  }
  if (templateNode.kind === 'Section') {
    return TEMPLATE_NODE_TYPE.SECTION
  }
  if (templateNode.kind === 'Question') {
    return TEMPLATE_NODE_TYPE.QUESTION
  }
  return TEMPLATE_NODE_TYPE.UNKNOWN
}

export function getDefaultSection () {
  return {
    'id': uuid(),
    'displayOrder':1,
    'kind': 'Section',
    'title':'New Section',
    'type':'Questions',
    'groupedQuestions':false,
    'elements':[getDefaultQuestion()]
  }
}

export function getDefaultQuestion () {
  return {
    'id': uuid(),
    'displayOrder':1,
    'kind': 'Question',
    'title':'New Question',
    'questionType':'TEXTAREA',
    'questionTextType': 'LargeTextbox',
    'allowAttachments':false,
    'mandatory': true,
    'description':'New Question'
  }
}

export function visitTreeInPreOrder (root, visitor, childrenFieldName = 'children', isVisitorStopper) {
  function traverse (parent, node) {
    const vi = visitor({
      parent,
      node
    })

    if (isVisitorStopper && !!vi) { return }

    const children = node[childrenFieldName]
    if (children && children.length > 0) {
      children.forEach(child => {
        traverse(node, child)
      })
    }
  }
  traverse(null, root)
}

export function visitTreeInPostOrder (root, visitor, childrenFieldName = 'children') {
  function traverse (parent, node) {
    const children = node[childrenFieldName]
    if (children && children.length > 0) {
      children.forEach(child => {
        traverse(node, child)
      })
    }
    visitor({
      parent,
      node
    })
  }
  traverse(null, root)
}

// breadth-first traversal of the template tree structure (level by level)
export function visitTreeBreadthFirst (root, visitor, childrenFieldName = 'children') {
  let queue = []
  queue.push({
    parent: null,
    node: root
  })
  let newRoot = root
  while (queue.length > 0) {
    const { parent, node } = queue.shift()
    const convertedNode = visitor({ parent, node })
    if (!parent) {
      newRoot = convertedNode
    }
    const children = node[childrenFieldName]
    if (children && children.length > 0) {
      children.forEach(el => {
        queue.push({
          parent: convertedNode || node,
          node: el
        })
      })
    }
  }
  return newRoot
}

export function visitTemplateTree (templateRoot, visitor) {
  return visitTreeBreadthFirst(templateRoot, visitor, 'elements')
}

export function isAttachmentsAllowed (templateNode) {
  return isQuestionNode(templateNode) && templateNode.allowAttachments
}

export function addPlaceholderForMissingItem (logic, treeItems) {
  if (!logic) {
    return treeItems
  }
  if (logic.kind === 'Criterion') {
    const isItemPresentForLogic = treeItems.find(item => item.id === logic.value.subjectValue)
    if (!isItemPresentForLogic) {
      const placeholder = createPlaceholderForMissingItem(logic.value.subjectValue)
      treeItems.push(placeholder)
    }
  }
  const list = logic.value.list || []
  list.forEach(listItem => addPlaceholderForMissingItem(listItem, treeItems))
  return treeItems
}

function createPlaceholderForMissingItem (id) {
  return {
    id,
    title: id,
    data: {
      kind: 'Question'
    }
  }
}
