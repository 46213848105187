import { UserStatus, DelegationHistoryRoleReplacement, UserEntitlement } from './constants'
import { AllRegisteredUsers } from '../routes/CaptureFormEditor/components/CaptureTab/constants'
import _ from 'lodash'

export function getLowerCaseRole (role) {
  if (role === DelegationHistoryRoleReplacement.RESPONDENT) {
    return DelegationHistoryRoleReplacement.Owner
  }
  return role[0] + role.slice(1).toLowerCase()
}

export function getUserFullName (user) {
  if (!user) {
    return ''
  }
  return user.isGroupUser
    ? user.name || ''
    : `${user.name || ''} ${user.surname || ''}`
}

export function getUserFullNameAndEmail (user) {
  return `${getUserFullName(user)} (${(user && user.email) || ''})`
}

export function getUserFullNameEmailAndStatusForInput (user) {
  if (user) {
    const isActive = user && user.status && user.status === 'Active'
    return isActive
      ? getUserFullNameAndEmail(user)
      : `${getUserFullNameAndEmail(user)} (Deactivated)`
  } else {
    return ''
  }
}

export function isUserDelegated (user, userStatuses) {
  return userStatuses[user.id] === UserStatus.Delegated
}

export function isUserTaskClosed (user, userStatuses) {
  if (!user || !userStatuses) {
    return false
  }
  return userStatuses[user.id] === UserStatus.Closed
}

export function isUserNotActivated (user, userStatuses) {
  if (!user || !userStatuses) {
    return false
  }
  return userStatuses[user.id] === UserStatus.NotActivated
}

export function fillEmptyGroupWithUsers (users, groupMap) {
  users.forEach(u => {
    if (u.isGroup && !u.users) {
      u.users = groupMap[u.id].users
    }
  })
}

// flatten the usersandGroups array into an array which only has users
// this will also make sure the array doesn't have duplicated users
export function flattenUsers (usersAndGroups) {
  const usersMap = usersAndGroups.reduce((acc, r) => {
    let users = [r]
    if (r.isGroup && r.users) {
      users = r.users
    }
    users.forEach(u => {
      acc[u.id] = u
    })
    return acc
  }, {})
  return Object.keys(usersMap).map(k => usersMap[k])
}

export const flattenEntity = (entity, callback) => {
  let results = []
  if (Array.isArray(entity)) {
    entity.forEach(item => {
      results = results.concat(flattenEntity(item, callback))
    })
  } else {
    callback(entity, results)
  }
  return results
}

// Get a group map with groupId as the index
export function getGroupMap (reviewers) {
  if (!reviewers) {
    return {}
  }
  const collectGroup = (entity, results) => {
    entity.groups.forEach(group => {
      results.push(Object.assign({ users: [] }, group))
    })
  }

  const dummyEntity = {
    groups: []
  }

  const groups = flattenEntity(reviewers.associate || dummyEntity, collectGroup).concat(
    flattenEntity(reviewers.organisations || dummyEntity, collectGroup)
  )

  return groups.reduce((acc, group) => {
    acc[group.id] = group
    return acc
  }, {})
}

export function normalizeUsersAndGroups (userResponse) {
  if (!userResponse) {
    return []
  }
  const collectUserAndGroup = (entity, results) => {
    // Single entity
    const users = entity.users || []
    const groups = entity.userGroups || []
    users.forEach(user => {
      results.push({
        ...user,
        headerName: (entity.name || 'All') + ' Users'
      })
    })
    groups.forEach(group => {
      results.push({
        ...group,
        isGroup: true,
        headerName: (entity.name || 'All') + ' User Groups'
      })
      collectUserAndGroup(group, results)
    })
  }
  const usersAndGroups = []
  collectUserAndGroup(userResponse, usersAndGroups)
  return usersAndGroups
}

export function getUsersAndGroups (reviewers) {
  const collectUserAndGroup = (entity, results) => {
    // Single entity
    const users = entity.users || []
    const groups = entity.groups || []
    users.forEach(user => {
      results.push({
        ...user,
        headerName: entity.name + ' Users'
      })
    })
    groups.forEach(group => {
      results.push({
        ...group,
        isGroup: true,
        headerName: entity.name + ' User Groups'
      })
      collectUserAndGroup(group, results)
    })
  }

  const dummyEntity = {
    users: [],
    groups: []
  }
  const usersAndGroups = flattenEntity(reviewers.associate || dummyEntity, collectUserAndGroup).concat(
    flattenEntity(reviewers.organisations || dummyEntity, collectUserAndGroup)
  )
  usersAndGroups.forEach(u => {
    if (u.isGroup) {
      u.users = u.users || []
    }
  })

  return usersAndGroups
}

// We need to introduce a restriction so that it is impossible for admin to configure a review that would have a user
// in two different roles
export function validateUserRoles (owner, reviewers, approvers, contributors) {
  const allReviewers = flattenUsers(reviewers || [])
  const allContributors = flattenUsers(contributors || [])
  const allApprovers = flattenUsers(approvers || [])
  var allUserMap = allReviewers.concat(allContributors).concat(allApprovers).concat(owner).reduce((acc, u) => {
    acc[u.id] = u
    return acc
  }, {})
  return Object.keys(allUserMap).length === allReviewers.length + allContributors.length + allApprovers.length + 1
}

// filter out the users who have been delegated in other versions
export function filterDelegatedUsers (users, userStatuses) {
  const statuses = userStatuses || {}
  return users.filter(u => statuses[u.id] !== 'Delegated')
}

// Get all the users after applying the delegation history
export function applyDelegationHistory (revisionInfo, users, delegation, usersInfo, role) {
  const delegatedFromUsersMap = {}
  let history = (delegation && delegation.history) || []
  history.forEach(h => {
    if (h.role === role && usersInfo[h.from] && h.revision === revisionInfo.current) {
      delegatedFromUsersMap[h.from] = usersInfo[h.from]
    }
  })
  let allUsers = []
  Object.keys(delegatedFromUsersMap).forEach(userId => {
    if (users.filter(u => u.id === userId).length === 0) {
      allUsers.push(delegatedFromUsersMap[userId])
    }
  })
  return allUsers.concat(users)
}

export function filterDraftUsersOut (normalizedUsersAndGroups) {
  return normalizedUsersAndGroups.filter(user => user.status !== 'Draft')
}

export function isUserAdmin (user) {
  let isAdmin = false
  if (!user || !user.entitlements) {
    return isAdmin
  }
  const entitlements = user.entitlements
  const adminEntitlements = Object.keys(UserEntitlement)
  if (Object.keys(entitlements).length !== adminEntitlements.length) {
    return isAdmin
  }
  let counter = 0
  adminEntitlements.forEach(entitlement => {
    if (entitlements[entitlement]) {
      counter++
    }
  })
  return counter === adminEntitlements.length
}

export function addAllRegisterUserGroup (usersAndGroups) {
  return usersAndGroups ? [
    ...usersAndGroups,
    { ...AllRegisteredUsers,
      users:_.uniqBy(usersAndGroups.filter(user => !user.isGroup && user.status === 'Active'), 'id'),
      isGroup:true
    }] : []
}
