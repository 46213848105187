
import { isCriterionValid } from './criterionBase'

const CriterionSection = ({
  answers,
  subjectValue,
  operation,
  comparisonValue,
  subjectType,
  template,
  occurrenceCount }) => {
  const isValid = isCriterionValid(
    answers,
    operation,
    comparisonValue,
    false,
    subjectType,
    subjectValue,
    template,
    occurrenceCount)
  return {
    isValid: isValid
  }
}

export default CriterionSection
