import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ValidationMessage from 'components/ValidationMessage'
import SanitizedHtml from '../../../../components/SanitizedHtml'

// do the following cleanups:
// 1. remove the prevailing <br>
// 2. if there are no actual contents inside the html tags, remove all tags, e.g '<p> </p>\n' will be converted to ''
export function trimRichFormatText (text) {
  if (!text) {
    return text
  }
  const tagsToRemove = ['br', 'span', 'p']
  const element = document.createElement('div')
  element.innerHTML = text
  const innerText = element.innerText || ''
  if (!innerText || innerText.match('^\\s+$')) {
    return ' '
  }
  const elements = element.childNodes
  let brNode

  while (element.querySelector('span.ql-cursor:first-child') !== null) {
    brNode = element.querySelector('span.ql-cursor:first-child')
    brNode.parentNode.removeChild(brNode)
  }
  while (elements.length &&
    elements[0].innerText !== undefined &&
    elements[0].innerText.trim().length === 0 &&
    tagsToRemove.includes(elements[0].tagName)) {
    brNode = element.querySelector(`${elements[0].tagName}:first-child`)
    element.removeChild(brNode)
  }
  while (elements.length &&
    elements[elements.length - 1].innerText !== undefined &&
    elements[elements.length - 1].innerText.trim().length === 0 &&
    tagsToRemove.includes(elements[elements.length - 1].tagName)) {
    brNode = element.querySelector(`${elements[elements.length - 1].tagName}:last-child`)
    element.removeChild(brNode)
  }
  return element.innerHTML || ' '
}

const QuestionDetails = props => {
  const description = props.isCaptureApprovalQuestion
    ? trimRichFormatText(props.title)
    : trimRichFormatText(props.description)
  const explanatoryText = trimRichFormatText(props.explanatoryText)

  const displayOrder = props.displayOrders ? props.displayOrders.join('.') : ''

  var newDiv = document.createElement('div')
  newDiv.innerHTML = description
  const childNodes = newDiv.childNodes
  if (childNodes[0].nodeType !== Node.TEXT_NODE) {
    if (displayOrder !== '') {
      childNodes[0].insertAdjacentHTML('afterbegin',
        '<span class="displayOrder"><b>' + displayOrder + '</b>' + '&nbsp;&nbsp;</span>')
    }
  } else {
    if (displayOrder !== '') {
      newDiv.insertAdjacentHTML('afterbegin',
        '<span class="displayOrder"><b>' + displayOrder + '</b>' + '&nbsp;&nbsp;</span>')
    }
  }
  return (
    <Fragment>
      <div className='first-line'>
        {description &&
          <div className='question-description'>
            <SanitizedHtml text={newDiv.innerHTML} />
          </div>
        }
        {props.withValidation &&
          <ValidationMessage
            isRequired={props.isQuestionAnswerRequired}
            isValidate={props.validation && props.validation.isValidate}
            isValid={props.isValid}
            isHideValidation={props.isHideValidation}
          />
        }
      </div>
      {explanatoryText && explanatoryText.trim().length !== 0 &&
        <div
          className='question-explanatory-text'
        >
          <SanitizedHtml text={explanatoryText} />
        </div>}
      {props.isEditable !== undefined && !props.isEditable &&
        <div className='editable-note'>
          <span>
              This answer may not be edited.
              Any answer below is pre-populated from a prior source
              and is shown for informational purposes only.
          </span>
        </div>
      }
    </Fragment>
  )
}

QuestionDetails.propTypes = {
  explanatoryText: PropTypes.string,
  description: PropTypes.string,
  validation: PropTypes.object,
  isQuestionAnswerRequired: PropTypes.bool,
  isEditable: PropTypes.bool,
  isValid: PropTypes.bool,
  isHideValidation: PropTypes.bool,
  withValidation: PropTypes.bool,
  displayOrders: PropTypes.array,
  isCaptureApprovalQuestion: PropTypes.bool,
  title: PropTypes.string
}

QuestionDetails.defaultProps = {
  withValidation: true
}

export default QuestionDetails
