import LogicSupplierNodeKindFactory from './logicSupplierKindFactory'
export const isDisplayLogicValid = (displayLogic, answers, template) => {
  const param = displayLogic.value
  param.answers = answers
  param.unary = displayLogic.unary
  param.template = template
  const displayLogicNode = LogicSupplierNodeKindFactory(displayLogic.kind)(param)

  const isDisplayLogicValid = displayLogicNode.isValid
  return isDisplayLogicValid
}
