import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : '/review-config/:reviewId',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
       dependencies for bundling   */
      const ReviewConfigContainer = require('./containers/ReviewConfigContainer').default

      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, { key: 'config', reducer: require('./modules/reviewConfig').default })
      injectReducer(store, { key: 'allocation', reducer: require('./modules/questionAllocation').default })
      injectReducer(store, { key: 'activation', reducer: require('./modules/reviewActivation').default })

      cb(null, ReviewConfigContainer)

      /* Webpack named bundle   */
    }, 'reviewConfig')
  }
})
