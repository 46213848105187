import authProps from './authProps'
import userRoleProps, { UserRole } from './userRoleProps'
import { ternary, branch } from './branch'
import withSpinner from './withSpinner'
import withNotifications from './withNotifications'
import withNotificationOnly from './withNotificationOnly'
import systemPreferenceProps from './systemPreferenceProps'

export const compose = (...hocComponents) => (BaseComponent) => {
  let wrappedComponent = BaseComponent
  for (let i = hocComponents.length - 1; i >= 0; i--) {
    const hoc = hocComponents[i]
    wrappedComponent = hoc(wrappedComponent)
  }
  return wrappedComponent
}

export { UserRole, withSpinner, ternary, branch, withNotifications, withNotificationOnly }
export const accessControlProps = compose(authProps, userRoleProps, systemPreferenceProps)
