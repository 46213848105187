import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { retrieveSystemPreferences } from '../store/systemPreferences'

const mapDispatchToProps = {
  retrieveSystemPreferences
}

const mapStateToProps = (state) => ({
  isGlobalAttachmentsEnabled: state.systemPreferences.isGlobalAttachmentsEnabled,
  isGlobalLinkifyEnabled: state.systemPreferences.isGlobalLinkifyEnabled
})

// High Order Component - systemPreferenceProps which takes a BaseComponent and returns a new component with
// system preference properties injected
export default (BaseComponent) => {
  class SystemPreferenceWrapper extends React.PureComponent {
    componentDidMount () {
      this.props.retrieveSystemPreferences()
    }
    render () {
      return <BaseComponent {...this.props} />
    }
  }
  SystemPreferenceWrapper.propTypes = {
    retrieveSystemPreferences: PropTypes.func,
    isRetrievingSystemPreference: PropTypes.bool
  }

  return connect(mapStateToProps, mapDispatchToProps)(props => (
    <SystemPreferenceWrapper {...props} />
  ))
}
