import messages from './message'
import API from '../api'
import { normalizeUsersAndGroups } from '../models/user'

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
export const API_ERROR = 'API_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const REVIEW_ARCHIVED = 'REVIEW_ARCHIVED'
export const REVIEW_CLOSING = 'REVIEW_CLOSING'
export const SHOW_ARCHIVE_MODAL = 'SHOW_ARCHIVE_MODAL'
export const DISMISS_ARCHIVE_MODAL = 'DISMISS_ARCHIVE_MODAL'
export const INIT_SHARED_STATE = 'INIT_SHARED_STATE'
export const SHOW_UI_MODAL = 'SHOW_UI_MODAL'
export const DISMISS_UI_MODAL = 'DISMISS_UI_MODAL'
export const SET_D3_REDIRECTION_DELAY = 'SET_D3_REDIRECTION_DELAY'
export const SET_PROCESSING_DATA = 'SET_PROCESSING_DATA'
export const LOAD_ALL_USERS_LIST = 'LOAD_ALL_USERS_LIST'

export const ModalType = {
  CloseReview: 'CloseReview',
  ApproverQuestionForm: 'ApproverQuestionForm',
  AddCaptureApproverModal: 'AddCaptureApproverModal',
  AddCaptureRespondentModal: 'AddCaptureRespondentModal',
  Delegations: 'Delegations',
  Participants: 'Participants',
  AddReviewerModal: 'AddReviewerModal'
}

// ------------------------------------
// Actions
// ------------------------------------

export function setProcessingData (isProcessing) {
  return {
    type: SET_PROCESSING_DATA,
    payload: isProcessing
  }
}

export function showNotification (notification) {
  if (!notification.id) {
    notification.id = (new Date()).getTime()
  }
  return {
    type: SHOW_NOTIFICATION,
    payload: notification
  }
}

export function dismissNotification (notification) {
  return {
    type: DISMISS_NOTIFICATION,
    payload: notification
  }
}

export function resetError () {
  return {
    type: RESET_ERROR
  }
}

export function showValidationError (message) {
  return {
    type: VALIDATION_ERROR,
    payload: message
  }
}

export const initD3RedirectDelay = () => dispatch => {
  const delay = parseFloat(sessionStorage.getItem('d3RedirectionDelay') || 0)
  dispatch(setD3RedirectDelay(delay))
}

let timeoutId
export const setD3RedirectDelay = (delayInSeconds = 5) => dispatch => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  sessionStorage.setItem('d3RedirectionDelay', String(delayInSeconds))
  dispatch({
    type: SET_D3_REDIRECTION_DELAY,
    payload: delayInSeconds
  })
  // after the time is eclipsed, no need to wait
  timeoutId = setTimeout(() => {
    sessionStorage.removeItem('d3RedirectionDelay')
    dispatch({
      type: SET_D3_REDIRECTION_DELAY,
      payload: 0
    })
  }, delayInSeconds * 1000)
}

export const archiveReview = review => dispatch => {
  API.put(`/api/concur/review/${review.sessionId}/archive`).then(res => {
    if (!res.ok) {
      throw res
    }
    dispatch(dismissArchiveModal())
    dispatch({
      type: REVIEW_ARCHIVED
    })
  }).catch(error => {
    dispatch(dismissArchiveModal())
    API.handleError(error, dispatch)
  })
}

export const closeReview = (review, values, reviewClosedCallback) => dispatch => {
  dispatch({
    type: REVIEW_CLOSING,
    payload: true
  })
  const fileParentEntityType = 'Review'
  API.uploadAttachment(values.attachment, fileParentEntityType, review.sessionId).then((response) => {
    const body = {
      reason: values.reason.description,
      comment: values.comment || '',
      attachment: response.key,
      associateId: review.associateId,
      sendNotificationEmail: values.sendNotificationEmail
    }
    return API.put(`/api/concur/review/${review.sessionId}/close`, JSON.stringify(body))
  }).then(res => {
    if (!res.ok) {
      throw res
    }
    dispatch(dismissModal(ModalType.CloseReview))
    dispatch({
      type: REVIEW_CLOSING,
      payload: false
    })
    if (reviewClosedCallback) {
      reviewClosedCallback()
    }
  }).catch(error => {
    dispatch(dismissModal(ModalType.CloseReview))
    dispatch({
      type: REVIEW_CLOSING,
      payload: false
    })
    API.handleError(error, dispatch)
  })
}

/*
* {title, status, archived}
* */
export const initSharedState = review => {
  return {
    type: INIT_SHARED_STATE,
    payload: review
  }
}

export const showArchiveModal = () => ({
  type: SHOW_ARCHIVE_MODAL
})

export const dismissArchiveModal = () => ({
  type: DISMISS_ARCHIVE_MODAL
})

export const showModal = (modalType) => ({
  type: SHOW_UI_MODAL,
  payload: modalType
})

export const dismissModal = (modalType) => ({
  type: DISMISS_UI_MODAL,
  payload: modalType
})

export const loadAllUsersList = () => (dispatch) => {
  const endPoint = '/api/platform/users/display/all'
  return API.getJson(endPoint)
    .then(allUsersList => {
      return dispatch({
        type: LOAD_ALL_USERS_LIST,
        payload: normalizeUsersAndGroups(allUsersList)
      })
    })
}

const ACTION_HANDLERS = {
  [SHOW_NOTIFICATION]: (state, action) => {
    return Object.assign({}, state, {
      notifications: [...state.notifications, action.payload]
    })
  },
  [DISMISS_NOTIFICATION]: (state, action) => {
    return Object.assign({}, state, {
      notifications: state.notifications.filter(n => n !== action.payload)
    })
  },
  [API_ERROR]: (state, action) => {
    const error = action.payload
    let message = error.message
    let title
    let actionTitle
    let extraAction
    let extraActionTitle

    if (error.key) {
      message = messages[error.key] || error.message
    } else if (typeof error === 'string') {
      message = error
    } else if (error.status === 401) {
      title = 'Logged Out'
      actionTitle = 'Close'

      extraAction = () => {
        window.location.reload(false)
      }
      extraActionTitle = 'Refresh'

      // eslint-disable-next-line max-len
      message = `You have been logged out. The page needs to be refreshed to continue working. Refreshing will discard existing changes.
      
      Click Refresh to discard any changes and refresh the page.

      Click Close if you wish to copy any data and then manually refresh.`
    }

    return Object.assign({}, state, {
      error: {
        key: error.key,
        title: title,
        message: message,
        action: error.action,
        actionTitle: actionTitle,
        extraAction: extraAction,
        extraActionTitle: extraActionTitle
      }
    })
  },
  [VALIDATION_ERROR]: (state, action) => {
    return Object.assign({}, state, {
      error: {
        message: action.payload
      }
    })
  },
  [RESET_ERROR]: (state) => {
    return Object.assign({}, state, {
      error: null
    })
  },
  [INIT_SHARED_STATE]: (state, action) => {
    const review = action.payload
    return {
      ...state,
      review,
      associateId: review.associateId,
      isReviewArchived: review.archived
    }
  },
  [REVIEW_ARCHIVED]: (state) => {
    return {
      ...state,
      isReviewArchived: true
    }
  },
  [SHOW_ARCHIVE_MODAL]: (state) => {
    return {
      ...state,
      isArchiveModalVisible: true
    }
  },
  [DISMISS_ARCHIVE_MODAL]: (state) => {
    return {
      ...state,
      isArchiveModalVisible: false
    }
  },
  [SHOW_UI_MODAL]: (state, action) => {
    return {
      ...state,
      visibleModals: {
        ...state.visibleModals,
        [action.payload]: true
      }
    }
  },
  [DISMISS_UI_MODAL]: (state, action) => {
    return {
      ...state,
      visibleModals: {
        ...state.visibleModals,
        [action.payload]: false
      }
    }
  },
  [REVIEW_CLOSING]: (state, action) => {
    return {
      ...state,
      isClosingReview: action.payload
    }
  },
  [SET_D3_REDIRECTION_DELAY]: (state, action) => {
    return {
      ...state,
      d3RedirectionDelay: action.payload
    }
  },
  [SET_PROCESSING_DATA]: (state, action) => {
    return {
      ...state,
      isProcessingData: action.payload
    }
  },
  [LOAD_ALL_USERS_LIST]:(state, action) => {
    const newState = {
      ...state,
      allUsersList: action.payload

    }
    return newState
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  notifications: [],
  visibleModals: {},
  error: null,
  isArchiveModalVisible: false,
  isReviewArchived: false,
  // time in seconds that should be wait before redirecting to capture
  d3RedirectionDelay: 0,
  allUsersList: []
}

export default function sharedReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
