export default () => ({
  path : '/onboarding/db',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
      dependencies for bundling   */
      const OnboardingDBContainer = require('./containers/OnboardingDBContainer').default

      // DO not need to inject the reducer as we use React hooks
      cb(null, OnboardingDBContainer)
      /* Webpack named bundle   */
    }, 'onboardingDB')
  }
})
