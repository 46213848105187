import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : 'capture-form-list',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const Container = require('./containers/CaptureFormTableContainer').default

      const reducerNames = ['captureFormList']
      reducerNames.forEach(key => {
        const reducer = require(`./modules/${key}`).default
        injectReducer(store, { key, reducer })
      })

      /*  Return getComponent   */
      cb(null, Container)

    /* Webpack named bundle   */
    }, 'captureFormList')
  }
})
