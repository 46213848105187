const pubsub = {}

const e = pubsub.e = {}

function createEvent (name) {
  let event
  if (typeof (Event) === 'function') {
    event = new Event(name)
  } else {
    event = document.createEvent('Event')
    event.initEvent(name, true, true)
  }
  return event
}
pubsub.publish = function (name, data) {
  (e[ name ] = e[ name ] || createEvent(name)).data = data
  dispatchEvent(e[ name ])
}

pubsub.subscribe = function (name, handler) {
  addEventListener(name, handler)
}

pubsub.unsubscribe = function (name, handler) {
  removeEventListener(name, handler)
}

export default pubsub
