export default () => ({
  path : '/onboarding/all',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
      dependencies for bundling   */
      const OnboardingAllContainer = require('./containers/OnboardingAllContainer').default

      // DO not need to inject the reducer as we use React hooks
      cb(null, OnboardingAllContainer)
      /* Webpack named bundle   */
    }, 'onboardingAll')
  }
})
