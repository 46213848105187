import API from '../api'
// ------------------------------------
// Constants
// ------------------------------------
export const USER_ROLE_RETRIEVING = 'USER_ROLE_RETRIEVING'
export const USER_ROLE_RETRIEVED = 'USER_ROLE_RETRIEVED'
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR'

// ------------------------------------
// Actions
// ------------------------------------

export function retrieveUserRole (reviewId) {
  return (dispatch) => {
    if (reviewId) {
      dispatch({
        type : USER_ROLE_RETRIEVING
      })

      API.getJson(`/api/concur/review/${reviewId}/user`).then(response => {
        dispatch({
          type : USER_ROLE_RETRIEVED,
          payload : response
        })
      }).catch(err => {
        err.text().then(resText => {
          dispatch({
            type : USER_ROLE_ERROR,
            payload : resText
          })
        })
      })
    } else {
      dispatch({
        type : USER_ROLE_RETRIEVED,
        payload : {
          role: '1',
          allowToDelegate: false,
          allowToArchive: false
        } })
    }
  }
}

const ACTION_HANDLERS = {
  [USER_ROLE_RETRIEVING]: (state) => {
    return Object.assign({}, state, {
      isRetrievingRole: true
    })
  },
  [USER_ROLE_RETRIEVED]: (state, action) => {
    return Object.assign({}, state, {
      userRole: action.payload.role,
      allowToGetReview: action.payload.allowToGetReview,
      allowToModifyReview: action.payload.allowToModifyReview,
      allowToDelegate: action.payload.allowToDelegate,
      allowToArchive: action.payload.allowToArchive,
      isRetrievingRole: false
    })
  },
  [USER_ROLE_ERROR]: (state, action) => {
    return Object.assign({}, state, {
      roleError: action.payload,
      isRetrievingRole: false
    })
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isRetrievingRole: true,
  roleError: null,
  userRole: ''
}
export default function authReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
