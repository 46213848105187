import { OperandEnum, UnaryEnum, CountOperatorsEnum } from './constants'
import LogicSupplierNodeKindFactory from './logicSupplierKindFactory'

const isAndGroupValid = (list, answers, template) => {
  let isValid = true
  list.forEach(node => {
    const param = node.value
    param.answers = answers
    param.unary = node.unary
    param.template = template
    const displayLogicNode = LogicSupplierNodeKindFactory(node.kind)(param)
    const isDisplayLogicNodeValid = displayLogicNode.isValid
    if (!isDisplayLogicNodeValid) {
      isValid = false
    }
  })

  return isValid
}

const isOrGroupValid = (list, answers, template) => {
  let isValid = false
  list.forEach(node => {
    const param = node.value
    param.answers = answers
    param.unary = node.unary
    param.template = template
    const displayLogicNode = LogicSupplierNodeKindFactory(node.kind)(param)
    const isDisplayLogicNodeValid = displayLogicNode.isValid
    if (isDisplayLogicNodeValid) {
      isValid = true
    }
  })

  return isValid
}

const isCountGroupValid = (list, answers, template, operation, occurrenceCount) => {
  let collectedValidNodes = 0

  list.forEach(node => {
    const param = node.value
    param.answers = answers
    param.unary = node.unary
    param.template = template
    const displayLogicNode = LogicSupplierNodeKindFactory(node.kind)(param)

    if (displayLogicNode.isValid) {
      collectedValidNodes++
    }
  })

  switch (operation) {
    case CountOperatorsEnum.Less : {
      return collectedValidNodes < occurrenceCount
    }
    case CountOperatorsEnum.LessOrEquals : {
      return collectedValidNodes <= occurrenceCount
    }
    case CountOperatorsEnum.Equals : {
      return collectedValidNodes === occurrenceCount
    }
    case CountOperatorsEnum.MoreOrEquals : {
      return collectedValidNodes >= occurrenceCount
    }
    case CountOperatorsEnum.More : {
      return collectedValidNodes > occurrenceCount
    }
    default: {
      return false
    }
  }
}

const isUnaryInvert = (value) => (
  value && value === UnaryEnum.Invert
)

const Group = (params) => {
  const { unary, answers, operand, list, template, operation, occurrenceCount } = params
  let isValid = false

  switch (operand) {
    case (OperandEnum.And) : {
      isValid = isAndGroupValid(list, answers, template)
      break
    }
    case (OperandEnum.Or) : {
      isValid = isOrGroupValid(list, answers, template)
      break
    }
    case (OperandEnum.Count) : {
      isValid = isCountGroupValid(list, answers, template, operation, occurrenceCount)
      break
    }
  }

  if (isUnaryInvert(unary)) {
    isValid = !isValid
  }

  return {
    isValid : isValid
  }
}

export default Group
