import { API_ERROR } from '../store/shared'
import * as Sentry from '@sentry/react'

class FetchApi {
  setAuthToken (authToken) {
    this._authToken = authToken
  }

  call (url, options) {
    options = options || {}
    options.credentials = 'same-origin'
    options.headers = options.headers || {}
    options.headers = Object.assign({}, {
      'Auth-Token': this._authToken,
      'Cache-Control': 'no-cache',
      'pragma': 'no-cache'
    }, options.headers)

    return fetch(url, options)
  }

  post (url, body) {
    return this.call(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body
    })
  }

  // API which posts the params to the server, and parse the response as json
  query (url, params) {
    return this.getJson(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params || {})
    })
  }

  put (url, body) {
    return this.call(url, {
      method: 'put',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body
    })
  }

  getJson (url, options) {
    return this.call(url, options).then(res => {
      if (res.ok) {
        return res.text()
      }
      throw res
    }).then(res => {
      let json = ''
      try {
        json = JSON.parse(res)
      } catch (e) {
        console.warn(e)
      }
      return json
    })
  }

  postJson (url, body) {
    return this.call(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body
    }).then(res => {
      if (res.ok) {
        return res.blob()
      }
      throw res
    })
  }

  getText (url, options) {
    return this.call(url, options).then(res => {
      if (res.ok) {
        return res.text()
      }
      throw res
    })
  }

  getBlob (url, options) {
    return this.call(url, options).then(res => {
      if (res.ok) {
        return res.blob()
      }
      throw res
    })
  }

  postBlob (url, body) {
    return this.call(url, {
      method: 'POST',
      body
    }).then(res => {
      if (res.ok) {
        return res.blob()
      }
      throw res
    })
  }

  showApiError (error, dispatch, errorAction) {
    if (window.suppressApiError) {
      return
    }
    if (errorAction) {
      error.action = errorAction
    }

    dispatch({
      type: API_ERROR,
      payload: error
    })
  }

  handleError (errorRsp, dispatch, errorAction) {
    try {
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error)
        Sentry.captureException(errorRsp, scope)
      })
    } catch (e) {
      // ignore exceptions from Sentry
    }

    let errorPromise
    if (errorRsp.json) {
      if (errorRsp.status === 401) {
        errorPromise = Promise.resolve(errorRsp)
      } else if (errorRsp.status === 404) {
        errorPromise = errorRsp.text()
      } else {
        errorPromise = errorRsp.json()
      }
    } else if (errorRsp.text) {
      errorPromise = errorRsp.text()
    } else {
      errorPromise = Promise.resolve(errorRsp)
    }
    errorPromise.then((error) => {
      this.showApiError(error, dispatch, errorAction)
    })
  }
}
export default FetchApi
