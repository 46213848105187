import FetchApi from './fetchApi'

class ConcurApi extends FetchApi {
  parseJson (response, dispatch) {
    return response.text().then(text => {
      let json = {}
      try {
        json = JSON.parse(text)
        return json
      } catch (e) {
        this.handleError(response, dispatch)
      }
    })
  }

  getDashboardConfig (reviewId, revId) {
    const url = `/api/concur/review/${reviewId}/config/dashboard?${revId || ''}`
    return this.call(url).then(response => {
      if (!response.ok) {
        throw new Error('No rows config saved')
      }
      return response.json()
    }).catch(error => {
      console.warn('Error fetch rows config:', error)
      return []
    })
  }
  saveDashboardConfig (reviewId, revId, payload) {
    const url = `/api/concur/review/${reviewId}/config/dashboard?${revId || ''}`
    return this.call(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  }

  getUserStatus (reviewId) {
    return this.getJson(`/api/concur/review/${reviewId}/user/status`)
  }

  getQuestionDiffs (reviewId, fromRev, toRev) {
    return this.getJson(`/api/concur/report/${reviewId}/diffs?fromRev=${fromRev}&toRev=${toRev}`)
  }

  uploadAttachment (attachment, fileParentEntityType, fileParentEntityId) {
    if (!attachment) {
      return Promise.resolve({})
    }

    const readingFilePromise = new Promise(resolve => {
      const fileReader = new FileReader()
      fileReader.addEventListener('loadend', () => {
        resolve(fileReader.result)
      })
      fileReader.readAsArrayBuffer(attachment)
    })

    const fileName = encodeURIComponent(attachment.name || '')
    const headers = {
      accept: 'application/json, text/plain, */*',
      'Content-Length': attachment.size
    }
    if (attachment.type) {
      headers['content-type'] = attachment.type
    }
    return readingFilePromise.then(content => {
      // eslint-disable-next-line max-len
      return this.call(`/api/artifact-storage-proxy?name=${fileName}&fileParentEntityType=${fileParentEntityType}&fileParentEntityId=${fileParentEntityId}`,
        {
          method: 'put',
          headers,
          body: content
        }
      )
    }).then(response => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  closeReviewerTask (reviewId, payload) {
    return this.post(`/api/concur/review/${reviewId}/reviewer-close`, JSON.stringify(payload))
  }

  getClosedReviewers (reviewId) {
    return this.getJson(`/api/concur/review/${reviewId}/closed-reviewers-details`)
  }

  validateSubmission (reviewId, userRole) {
    return this.post(`/api/concur/review/${reviewId}/${userRole}-submit?dryrun=true`)
  }

  saveReviewerAllocations (reviewId, payload) {
    return this.post(`/api/concur/review/${reviewId}/reviewer-allocations`, JSON.stringify(payload))
  }
}

export default ConcurApi
