import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : '/login',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
       dependencies for bundling   */
      const LoginContainer = require('./containers/loginContainer').default

      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, { key: 'login', reducer: require('./modules/login').default })

      cb(null, LoginContainer)

      /* Webpack named bundle   */
    }, 'login')
  }
})
