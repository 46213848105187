export default () => ({
  path : '/example',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
       dependencies for bundling   */
      const EnvInfoContainer = require('./containers/EnvInfoContainer').default

      // DO not need to inject the reducer as we use React hooks

      cb(null, EnvInfoContainer)

      /* Webpack named bundle   */
    }, 'example')
  }
})
