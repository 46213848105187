
import { getAnswersForSingleQuestion } from '../answer'
import { isCriterionValid } from './criterionBase'
import { getQuestionById, isQuestionVisible } from '../question'

const CriterionQuestion = ({
  answers,
  subjectValue,
  operation,
  comparisonValue,
  template }) => {
  // check if the subject question itself is visible
  const subjectQuestion = getQuestionById(subjectValue, template.elements)
  if (subjectQuestion && !isQuestionVisible(subjectQuestion, answers, template)) {
    return {
      isValid: false
    }
  }

  const questionAnswers = getAnswersForSingleQuestion(answers, subjectValue)

  const isOptionComparison = (questionAnswers &&
     questionAnswers.length > 0 &&
     questionAnswers[0].answerOption !== undefined)

  const isValid = isCriterionValid(
    questionAnswers,
    operation,
    comparisonValue,
    isOptionComparison)
  return {
    isValid: isValid
  }
}

export default CriterionQuestion
