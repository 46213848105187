import { ReviewStatus, UserRole } from './constants'
import moment from 'moment'

export function checkIsReviewLive (review) {
  return !isReviewComplete(review)
}

export function isReviewComplete (review) {
  return (review.archived ||
    review.status === ReviewStatus.Approved ||
    review.status === ReviewStatus.Cancelled ||
    review.status === ReviewStatus.Finished ||
    review.status === ReviewStatus.Closed ||
    review.status === ReviewStatus.Rejected
  )
}

export function isReviewNonDelegable (review) {
  return (
    isReviewComplete(review) || review.status === ReviewStatus.Inactive
  )
}

export const isReviewReadOnly = (review, revisionInfo, userStatuses, userRole, currentUser) => {
  if (!review || !revisionInfo || !currentUser || !userStatuses) {
    return true
  }
  if (revisionInfo.current < revisionInfo.count) {
    return true
  }
  if (userRole === UserRole.CONTRIBUTOR) {
    return true
  }
  if (userRole === UserRole.Reviewer && review.status === ReviewStatus.PendingApprovals) {
    return true
  }
  if (review.status === ReviewStatus.PendingRevision ||
    review.status === ReviewStatus.Approved ||
    review.status === ReviewStatus.Cancelled ||
    review.status === ReviewStatus.Finished ||
    review.status === ReviewStatus.Closed ||
    review.status === ReviewStatus.Rejected) {
    return true
  }
  const userStatus = userStatuses[currentUser.id] || 'Open'
  return userRole !== UserRole.Owner && userStatus !== 'Open'
}

export const isReviewArchivable = (review) => {
  if (!review) {
    return false
  }
  const reviewStatus = review.status
  return (!reviewStatus ||
    reviewStatus === ReviewStatus.Inactive ||
    reviewStatus === ReviewStatus.Cancelled ||
    reviewStatus === ReviewStatus.Finished ||
    reviewStatus === ReviewStatus.Rejected ||
    reviewStatus === ReviewStatus.Closed ||
    reviewStatus === ReviewStatus.Approved
  )
}

export const isReviewClosable = review => {
  if (!review) {
    return false
  }
  const reviewStatus = review.status
  return (
    reviewStatus === ReviewStatus.PendingReview ||
    reviewStatus === ReviewStatus.PendingApprovals ||
    reviewStatus === ReviewStatus.PendingRevision ||
    reviewStatus === ReviewStatus.ReviewCompleted
  )
}

export const isGroupDiscussionAllowed = (review, revisionInfo) => {
  if (!review || !revisionInfo) {
    return false
  }
  if (revisionInfo.current < revisionInfo.count) {
    return false
  }
  // While the review is being revised, the version is in a state of change so group discussion is closed.
  if (review.status === ReviewStatus.Approved ||
    review.status === ReviewStatus.Cancelled ||
    review.status === ReviewStatus.Finished ||
    review.status === ReviewStatus.Closed ||
    review.status === ReviewStatus.PendingRevision ||
    review.status === ReviewStatus.Rejected) {
    return false
  }
  return true
}

export function formattedUtcTime (time) {
  return moment(time).utc().format('DD MMM YYYY, HH:mm A') + ' (UTC)'
}

export function formattedTime (time) {
  return moment.utc(time).format('DD MMM YYYY [\xa0] hh:mm A')
}
