export default () => ({
  path : '/onboarding',
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
      dependencies for bundling   */
      const OnboardingContainer = require('./containers/OnboardingContainer').default

      // DO not need to inject the reducer as we use React hooks
      cb(null, OnboardingContainer)
      /* Webpack named bundle   */
    }, 'onboarding')
  }
})
