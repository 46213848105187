import React from 'react'

export const ternary = (tester, ComponentA, ComponentB) => {
  return (props) => {
    if (tester(props)) {
      return <ComponentA {...props} />
    }
    return <ComponentB {...props} />
  }
}

export const branch = (tester, Component) => ElseComponent => {
  return ternary(tester, Component, ElseComponent)
}
