import { isDisplayLogicValid } from './displayLogic/displayLogic'
import { visitTemplateTree } from 'models/template'

// add the display order to the title, and store the title as originalTitle
const enrichTitle = (treeRoot, answers) =>

  visitTemplateTree(treeRoot, ({ parent, node }) => {
    const parentDisplayOrders = (parent && parent.displayOrders) || []
    const displayOrders = [...parentDisplayOrders]
    if (node.displayOrder) {
      displayOrders.push(node.displayOrder)
    }
    const treeNode = {
      ...node,
      displayOrders,
      originalTitle: node.title,
      title: `${displayOrders.join('.')}  ${node.title || ''}`,
      elements: []
    }
    if (parent) {
      parent.elements = parent.elements || []
      parent.elements.push(treeNode)
    }
    return treeNode
  })

// calculate the entire tree without pointer logic, so this tree can be used for global validation.
export const getCurrentTreeData = (template, answers) => {
  const elements = template.elements
  const elementsWithRoot = enrichTitle(addTreeRoot(elements), answers)
  const elementTree = buildElementTreeNode(-1, elementsWithRoot, answers, template)
  const elementTreeWithoutRoot = removeTreeRoot(elementTree)
  const formattedElementTree = setLastQuestion(elementTreeWithoutRoot)
  const confirmationQuestion = getConfirmationQuestion(elements)
  formattedElementTree.confirmationQuestion = confirmationQuestion
  return formattedElementTree
}

export const addTreeRoot = (elements) => {
  return {
    id: 'id',
    title: 'title',
    kind: 'Section',
    elements : elements
  }
}

export const removeTreeRoot = (elementTree) => {
  elementTree.flattenTree.shift()
  return {
    hierarchyTree : elementTree.hierarchyTree.children,
    flattenTree: elementTree.flattenTree
  }
}

export const setLastQuestion = (elementTree) => {
  let lastQuestion = elementTree.flattenTree[elementTree.flattenTree.length - 1]
  if (lastQuestion.isSectionElementDisplayAllQuestions) {
    let lastSection = elementTree.flattenTree.find(item => item.key === lastQuestion.parentKey)
    lastSection.isLastQuestion = true
  } else {
    lastQuestion.isLastQuestion = true
  }

  return elementTree
}

export const findLastQuestion = flattenTree => flattenTree.find(e => e.isLastQuestion)

// current node's children flatten tree
const _isNodeSection = (flattenTree) => flattenTree &&
    flattenTree.length === 1 && flattenTree[0].isSectionElement

const _isNodeHtmlSection = (flattenTree) => flattenTree &&
    flattenTree.length === 1 && flattenTree[0].isSectionElement &&
    flattenTree[0].isHtmlSection

const buildElementTreeNode =
  (flattenTreeIndex, element, answers, template,
    isDisplayAllQuestions, parentKey, isConfirmationQuestion) => {
    let elementTreeNode = {
    // hierarchy tree for single element node.
      hierarchyTree : {},
      // flatten tree for single element node.
      flattenTree: [],
      flattenTreeIndex: flattenTreeIndex,
      isDisplayElement: false
    }

    if (_isDisplayCurrentElement(element, answers, template)) {
      elementTreeNode.isDisplayElement = true
      const isSectionElement = _isSectionElement(element)
      const isHtmlSection = _isHtmlSection(element)
      const isSectionElementDisplayAllQuestions = _isSectionElementDisplayAllQuestions(element)
      const isConfirmationSection = _isConfirmationSection(element)

      let hierarchyTreeNode = {
        key: element.id,
        title: element.title,
        expanded: true
      }

      let flattenTreeNode = {
        index: flattenTreeIndex,
        isSectionElement: isSectionElement,
        isHtmlSection: isHtmlSection,
        htmlSectionContent: element.content,
        referenceDataSection: element.referenceDataSection,
        isConfirmationQuestion: isConfirmationQuestion,
        isLastQuestion: false,
        title: element.title,
        key: element.id,
        parentKey: parentKey
      }

      if (isSectionElementDisplayAllQuestions) {
        flattenTreeNode.questions = element.elements
        flattenTreeNode.isSectionElementDisplayAllQuestions = true
      } else if (!isSectionElement) {
        flattenTreeNode.isQuestionElement = true
        if (isDisplayAllQuestions) {
          flattenTreeNode.parentKey = parentKey
          flattenTreeNode.isSectionElementDisplayAllQuestions = true
        } else {
          flattenTreeNode.questions = [element]
        }
      }

      elementTreeNode.flattenTree.push(flattenTreeNode)

      if (element.elements) {
        let children = []
        element.elements.forEach(e => {
          flattenTreeIndex = flattenTreeIndex + 1
          const childNode = buildElementTreeNode(
            flattenTreeIndex,
            e,
            answers,
            template,
            isSectionElementDisplayAllQuestions,
            element.id,
            isConfirmationSection)

          if (childNode.isDisplayElement) {
            // html section
            if (_isNodeHtmlSection(childNode.flattenTree) ||
            // qustions section with valid questions.
              !_isNodeSection(childNode.flattenTree)) {
              elementTreeNode.flattenTree = elementTreeNode.flattenTree.concat(childNode.flattenTree)
              children.push(childNode.hierarchyTree)
              flattenTreeIndex = childNode.flattenTreeIndex
            } else {
              flattenTreeIndex = flattenTreeIndex - 1
            }
          } else {
            flattenTreeIndex = flattenTreeIndex - 1
          }
        })
        hierarchyTreeNode.children = children
      }
      elementTreeNode.hierarchyTree = hierarchyTreeNode
      elementTreeNode.flattenTreeIndex = flattenTreeIndex
    }

    return elementTreeNode
  }

export const getConfirmationQuestion = (elements) => {
  let element = elements.find(e => _isConfirmationSection(e))
  const isSectionElementDisplayAllQuestions =
    _isSectionElementDisplayAllQuestions(element)

  let node = {
    index: 0,
    isSectionElement: true,
    isConfirmationQuestion: true,
    isLastQuestion: false,
    title: element.title,
    key: element.id,
    questions : element.elements,
    isSectionElementDisplayAllQuestions : isSectionElementDisplayAllQuestions
  }

  return node
}

export const getAllSectionKeysFromFlattenNodes = (nodes) => {
  return nodes.filter(n => {
    if (n && n.isSectionElement) { return n }
  }).map(sectionNode => {
    return sectionNode.key
  })
}

// ignore the keys will get hidden, only work on the new keys will show.
// when the keys will get hide, rc-tree ignores the addtional expanded keys.
export const updateExpandedKeys = (expandedKeys, prevNodes, nextNodes) => {
  let updatedKeys = expandedKeys
  // add new displayed section key as expanded key.
  nextNodes.forEach(nn => {
    if (nn.isSectionElement &&
      !prevNodes.some(pn => pn.key === nn.key)) {
      updatedKeys.push(nn.key)
    }
  })

  return updatedKeys
}

export const isSectionVisible = (element, answers, template) => {
  return _isSectionElement(element) &&
    _isDisplayCurrentElement(element, answers, template)
}

const _isSectionElement = (element) => (
  element.kind === 'Section'
)

const _isHtmlSection = (element) => (
  _isSectionElement(element) &&
  element.type === 'HTMLInfoPage'
)

const _isSectionElementDisplayAllQuestions = (element) => (
  element.kind === 'Section' && element.groupedQuestions
)

const _isConfirmationSection = (element) => (
  element.type === 'Confirmation'
)

const _isDisplayCurrentElement = (element, answers, template) => {
  let isConfirmationSection = _isConfirmationSection(element)
  if (isConfirmationSection) {
    return false
  }
  let displayLogic =
    element.questionTemplateBusinessLogic ||
    element.sectionTemplateBusinessLogic
  // if element contains any display logic, do the display logic caculation
  if (displayLogic && displayLogic.value) {
    const isDisplayElement = isDisplayLogicValid(displayLogic, answers, template)
    return isDisplayElement
  }
  // if element doesn't contains any display logic, display the element.
  return true
}

// if question key not exists, return the last non confirmation question key.
export const returnLastNonConfirmationQuestionKey = (key, flattenList) => {
  const section = flattenList.some(e => e.key === key)
  if (!section) {
    const lastQuestion = findLastQuestion(flattenList)
    return lastQuestion.key
  }
  return key
}
