import { injectReducer } from '../../store/reducers'

export default (store) => ({
  path : 'external-user-group-editor/(:mode)/(:userGroupId)',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const ExternalUserGroupEditorContainer = require('./containers/ExternalUserGroupEditorContainer').default
      const reducer = require('./modules/externalUserGroupEditor').default

      injectReducer(store, { key: 'externalUserGroupEditor', reducer })

      /*  Return getComponent   */
      cb(null, ExternalUserGroupEditorContainer)

    /* Webpack named bundle   */
    }, 'ExternalUserGroupEditor')
  }
})
