import { isDisplayLogicValid } from './displayLogic/displayLogic'
import { ElementType, QuestionType, QuestionTextType } from './constants'
import { isSectionVisible } from './section.js'

export const getQuestionById = (questionId, elements) => {
  let result

  for (const e of elements) {
    if (ElementType.Question === e.kind && e.id === questionId) {
      result = e
    } else if (e.elements && e.elements.length > 0) {
      result = getQuestionById(questionId, e.elements)
    }

    if (result) break
  }

  return result
}

export const isQuestionVisible = (question, answers, template) => {
  if (!_isQuestionElement(question)) {
    return false
  }

  // check if the parent section is visible
  let parentSections = getSectionsForQuestion(question, template.elements)
  if (parentSections.some(section => !isSectionVisible(section, answers, template))) {
    return false
  }

  let displayLogic = question.questionTemplateBusinessLogic
  // if question contains any display logic, do the display logic caculation
  if (displayLogic && displayLogic.value) {
    return isDisplayLogicValid(displayLogic, answers, template)
  }

  // if question doesn't contains any display logic, display the question.
  return true
}

export const getSectionsForQuestion = (question, elements) => {
  var sections = elements.filter(e => (ElementType.Section === e.kind))
    .filter(e => e.elements && e.elements.find(c => (ElementType.Question === c.kind) && (question.id === c.id)))

  return sections
}

export const getDisplayQuestions = (questions, answers, template) => (
  questions.filter(q => isQuestionVisible(q, answers, template))
)

// all questions in the template hirarchy
export const getAllQuestions = (elements, allQuestions) => {
  elements.filter(e => {
    if (ElementType.Question === e.kind) {
      allQuestions.push(e)
    }
    if (e.elements && e.elements.length > 0) {
      allQuestions = getAllQuestions(e.elements, allQuestions)
    }
  })
  return allQuestions
}

// all display questions in the template hirarchy
export const getAllDisplayQuestionIds = (elements, answers, template) => {
  const allQuestions = getAllQuestions(elements, [])
  const allDisplayQuestions = getDisplayQuestions(allQuestions, answers, template)
  return allDisplayQuestions.map(q => (q.id))
}

export const isGuidanceQuestion = (question) => {
  return question &&
  String(question.questionType.toUpperCase()) === QuestionType.Guidance
}

export const getTextQuestionMaxCharCount = (textQuestionType) => {
  const map = {
    [QuestionTextType.InputBoxLarge]: Number.MAX_VALUE,
    [QuestionTextType.InputBoxMedium]: 200,
    [QuestionTextType.InputBoxSmall]: 50
  }
  return map[textQuestionType] || Number.MAX_VALUE
}

export const isQuestionAnswerOptions = (question) => {
  return question &&
    question.multipleChoices &&
    question.multipleChoices.length > 0
}

export const isDateQuestion = (question) => {
  return question && question.questionType === 'DATE_CHOICE'
}

const _isQuestionElement = (element) => (
  ElementType.Question === element.kind
)

export const isAnswerEditable = (answer) => {
  return !(answer.editability === 'NOT_EDITABLE_IF_POPULATED' && answer.propagationSourceId)
}
