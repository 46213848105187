import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { getAuthToken, setAuthToken, authenticateUser, heartbeat } from '../store/auth'
import Loader from '../components/Loader'
import { AdminPathToBeBlockedForXu, AdminPathToBeBlockedForNoneAdminAndObservers } from '../models/constants'
import RequiredWarningModal from '../components/RequiredWarningModal'

const mapDispatchToProps = {
  setAuthToken,
  authenticateUser,
  heartbeat
}

const mapStateToProps = (state, ownProps) => ({
  tid: ownProps.location.query.tid,
  user: ownProps.location.query.user,
  authToken: state.auth.authToken,
  currentUser: state.auth.user,
  isAuthenticating: state.auth.isAuthenticating,
  authError: state.auth.authError
})

function removeAuthTokenFromUrl () {
  let [url = '', queryString = ''] = location.href.split('?')
  queryString = queryString.split('&').filter(s => s.indexOf('tid=') === -1).join('&')
  history.replaceState(null, '', [url, queryString].join('?'))
}

const checkLocationPathError = (props) => {
  const { currentUser: { isUserAdmin, isExternalUser }, router } = props
  const adminPath = isExternalUser ? AdminPathToBeBlockedForXu : AdminPathToBeBlockedForNoneAdminAndObservers
  let isAdminPath = adminPath.includes(router.routes[1].path)
  if (isAdminPath) {
    if (isUserAdmin) {
      return false
    } else {
      return true
    }
  }
  return false
}

function redirectToHomePage () {
  location.href = `/home`
}

// High Order Component - authProps which takes a BaseComponent and return a new component with the auth related
// properties injected
export default (BaseComponent) => {
  class AuthWrapper extends React.PureComponent {
    isPreAuthenticating = true
    timerId = null

    constructor (props) {
      super(props)

      const tid = props.tid || getAuthToken()
      if (tid) {
        this.isPreAuthenticating = false
      }

      // remove any stale event
      window.localStorage.removeItem('SENDING_SHARED_DATA')

      // subscribe to local storage events
      window.addEventListener('storage', (event) => {
        const tid = props.tid || getAuthToken()
        if (event.key === 'REQUESTING_SHARED_DATA' && tid) {
          // set the token, so that listening tabs receive it as an event
          window.localStorage.setItem('SENDING_SHARED_DATA', tid)
          // and immediately remove it, so that it doesn't stay in the local storage
          window.localStorage.removeItem('SENDING_SHARED_DATA')
        } else if (event.key === 'SENDING_SHARED_DATA' && event.newValue && tid !== event.newValue) {
          // cancel the timeout, if any
          this.cancelPreAuthTimeout()
          // perform the authentication with the new token
          this.isPreAuthenticating = false
          this.doAuthentication(event.newValue)
        }
      })
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount () {
      // perform the authentication with the existing token
      if (!this.isPreAuthenticating) {
        this.doAuthentication()
      }
    }

    componentDidMount () {
      // to stop the user from being logged out
      // this.startHeartbeat()

      // if pre-authenticating (i.e. requesting the token from other tabs)
      if (this.isPreAuthenticating && !this.timerId) {
        // check if we have a tid
        const tid = this.props.tid || getAuthToken()
        if (!tid) {
          // request TID from existing tabs
          window.localStorage.setItem('REQUESTING_SHARED_DATA', Date.now().toString())
          window.localStorage.removeItem('REQUESTING_SHARED_DATA')
        }

        this.startPreAuthTimeout()
      }
    }
    componentWillUnmount () {
      // this.stopHeartbeat()
      this.cancelPreAuthTimeout()
    }
    startHeartbeat () {
      this._heartbeatTimer = setTimeout(() => {
        this.props.heartbeat()
        // start the next one
        this.startHeartbeat()
      }, 45000)
    }
    stopHeartbeat () {
      if (this._heartbeatTimer) {
        clearTimeout(this._heartbeatTimer)
        this._heartbeatTimer = null
      }
    }
    startPreAuthTimeout () {
      // set a 100ms timeout
      this.timerId = setTimeout(() => {
        this.timerId = null

        const props = this.props
        props.setAuthToken(props.tid)
        props.authenticateUser(props.user)
      }, 100)
    }
    cancelPreAuthTimeout () {
      if (this.timerId) {
        clearTimeout(this.timerId)
        this.timerId = null
      }
    }
    doAuthentication (newtid) {
      const props = this.props
      props.setAuthToken(newtid || props.tid)
      props.authenticateUser(props.user)
    }

    render () {
      removeAuthTokenFromUrl()

      const { isAuthenticating, authError } = this.props
      if (isAuthenticating) {
        return <Loader text='Authenticating user' />
      }
      if (authError) {
        return (
          <Alert bsStyle='danger'>
            <h4>Authentication error</h4>
            <p style={{ 'wordWrap':'break-word' }}>{authError}</p>
          </Alert>
        )
      }
      let locationPathError = checkLocationPathError(this.props)
      if (locationPathError) {
        return (
          <Fragment>
            <RequiredWarningModal
              show={locationPathError}
              dismiss={redirectToHomePage}
              modalText='Access denied'
              modalHeader='Warning'
              className='small-warning-modal-dialog'
            />
            <Loader text='Authenticating user' />
          </Fragment>
        )
      }
      return <BaseComponent {...this.props} />
    }
  }
  AuthWrapper.propTypes = {
    setAuthToken: PropTypes.func,
    heartbeat: PropTypes.func,
    authenticateUser: PropTypes.func,
    authToken: PropTypes.string,
    tid: PropTypes.string,
    user: PropTypes.any,
    authError: PropTypes.string,
    isAuthenticating: PropTypes.bool
  }

  return connect(mapStateToProps, mapDispatchToProps)(props => (
    <AuthWrapper {...props} />
  ))
}
