import './third-party/bootstrap/css/bootstrap.min.css'
import 'fixed-data-table-2/dist/fixed-data-table.min.css'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

Sentry.init({
  dsn: window.location.protocol + '//1900306dd34049c1a24e73c1d47bb4fb@' + window.location.host + '/sentry/5354366',
  release: 'discovery4@' + process.env.npm_package_version,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['warn', 'error', 'assert']
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 0.5 // Be sure to lower this in production
})

Moment.locale('en')
momentLocalizer()

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = () => {
  const routes = require('./routes/index').default(store)

  ReactDOM.render(
    <AppContainer store={store} routes={routes} />,
    MOUNT_NODE
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    )
  }
}
// ========================================================
// Go!
// ========================================================
render()
