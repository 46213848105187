import React from 'react'
import Loader from '../components/Loader'
import { branch } from './branch'

const defaultLoadingChecker = props => props.isLoading
export default (loadingChecker, spinner) => {
  return branch(
    loadingChecker || defaultLoadingChecker,
    () => (spinner || <Loader />)
  )
}
